.form-control {
  border-radius: 0;
}

.input-group {
  margin-bottom: 0;
  width: 75%;
}

.close-bt {
  border: 30px;
  border-radius: 80%;
  background: red;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  margin-top: 10px;
  margin-right: 10px;
  color: white;
}
.close-bt:hover {
  box-shadow: 1px 1px 0px 1px rgb(231, 224, 224);
}
.verify-button {
  font-size: 14px;
}
.verifypage {
  height: 0;
}
/*----------------------------------------- */
              /* Media */
/*----------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
  .error-message {
    font-size: 12px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .error-message {
    font-size: 14px;
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .error-message {
    font-size: 14px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .error-message {
    font-size: 14px;
  }
}
@media (min-width: 1025px) and (max-width: 1199px) {
  .error-message {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .error-message {
    font-size: 14px;
  }
}
@media (min-width: 1400px) {
  .error-message {
    font-size: 14px;
  }
}
