.video-modal .modal-content {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.video-modal-body {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 960px;
}

.video-wrapper {
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
