
.bdy {
    position: relative;
  }
.top-dg{
    display: flex;
    width: 100%;
}
.top-dg .design{
    display: grid;
    width: 20%;
    height: 60px;
    
}
.logo{
    margin-left: auto;
    float: right;
}

.certificate-title{
    text-align: center;
    margin-top: 30px;
    padding: 15px;
    color: #16151C;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}
.title-sec{
    text-align: center;
    padding-top: 20px;
    color: #16151C;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
}
hr{
    width: 100;
}
.certificate-person-name{
    margin-bottom: 0px;
    padding: 5px;
    color: #00597D;
    text-align: center;
    font-family: Brush Script MT, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 35px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}
.para{
    margin-top: 10px;
    padding: 10px;
    color: #16151C;
    text-align: center;
    font-family: Arial,Brush Script MT, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 7.5px;
}
.img-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 75px; 
  }
  
  .img-bottom img {
    width: 70px; 
    height: auto; 
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; 
  }
  .leftside-bottom {
    position: absolute;
    bottom: 40px;
    left: 20px; 
    padding: 10px; 
    align-items: center;
  }
  
  .leftside-bottom img {
    width: 100px;
    height: auto;
    margin-left: 30px;
  }
  
  .leftside-bottom hr {
    margin: 0 1px; 
    width: 200px;
  }
  
  .leftside-bottom div {
    color: #555;
    font-weight: 600; 
  }
  .rightside-bottom {
    position: absolute;
    bottom: 40px;
    right: 20px; 
    padding: 10px; 
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .rightside-bottom div {
    margin-bottom: 8px; 
    color: #555; 
  }
  .button-7 {
    background-color: #0095ff;
    border: 1px solid transparent;
    /* top: 35px; */
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 0 auto;
    outline: none;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
  }
  
  .button-7:hover,
  .button-7:focus {
    background-color: #07c;
  }
  
  .button-7:focus {
    box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
  }
  
  .button-7:active {
    background-color: #0064bd;
    box-shadow: none;
  }
  .hr-line{
    display: flex;
    margin: 0 auto;
  }

  .close-btn{
    border: none;
    background: none;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
  }
