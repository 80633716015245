/*************************************************/
/*    Home Review Carousel CSS                   */
/*************************************************/
.review-container {
  display: flex;
  justify-content: center;
  position: relative;
  /* background: linear-gradient(to bottom, var(--bg-color), gray ); */
  margin-top: 16px;
  margin-bottom: 0px !important;
  padding: 0px;
}

.carousel-container {
  width: 100%;
  position: relative;
}

.card {
  width: 24rem; /* 384px */
  height: 18rem; /* 288px */
  margin-bottom: 40px !important;
  margin: 5px;
  background-size: 100% 100%;
  border: none;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background-image: linear-gradient(to bottom, white 70%, #15a8f3 20%);
  animation: scale-down 0.4s ease;
}

.card:hover {
  /* border: 0.5px solid #15a8f3; */
  border: 1px solid #1c3484;
  background-image: linear-gradient(
    to bottom,
    var(--txt-wht-color) 70%,
    #1c3484 20%
  );
  color: black;
  transform: scale(1.02);
}

.card:hover .card-footer .skill {
  color: var(--txt-wht-color);
}

.openDoublequote-svg {
  position: absolute;
  top: 20px;
  width: 38px !important;
  height: 38px !important;
  margin: 0;
  padding: 0;
  border: none !important;
  border-radius: 0 !important;
  display: block;
}

.card .card-body {
  max-height: 235px;
  background-color: var(--bg-white-color);
  border: none;
  padding: 30px;
}

.card .card-footer {
  border: none;
  background: none;
}

.review-content {
  z-index: 1;
  padding-top: 15px;
  padding-left: 30px;
  font-size: 14px;
  max-height: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.card img {
  margin-bottom: 10px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 80px;
  height: 80px;
  border: none;
  border-radius: 50%;
  border: 3.5px solid var(--btn-sky-blue);
}

.card .card-footer .name {
  font-size: 18px;
  font-weight: bold;
  color: var(--hover-bg);
  left: 100px;
  bottom: 80px;
  position: absolute;
  display: block;
  max-width: calc(100% - 120px);
  max-height: 28px;
  line-height: 28px;
  overflow: hidden;
  white-space: nowrap;
}

.card .card-footer .company {
  color: var(--hover-bg);
  font-size: 14px;
  width: calc(100% - 120px);
  left: 100px;
  margin-right: 10px;
  bottom: 55px;
  position: absolute;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

.card .card-footer .skill {
  color: var(--bg-white-color);
  font-size: 14px;
  left: 100px;
  bottom: 20px;
  position: absolute;
  max-width: calc(100% - 100px);
  overflow: hidden;
  white-space: nowrap;
}

.leftslide-carousel-reviews-arrow-image,
.rightslide-carousel-reviews-arrow-image {
  font-size: 20px;
  height: 60px;
  width: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  z-index: 2;
  cursor: pointer;
  background-color: var(--hover-bg);
  padding: 15px 0;
  border-radius: 10%;
  right: 5px;
}

.leftslide-carousel-reviews-arrow-image {
  left: 0px;
}

.rightslide-carousel-reviews-arrow-image {
  right: 0px;
}

.leftslide-carousel-reviews-arrow-image:hover,
.rightslide-carousel-reviews-arrow-image:hover {
  background-color: var(--btn-sky-blue) !important;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
}

.carousel-indicators li {
  display: none;
}

.carousel-indicators li:nth-child(-n + 4) {
  display: block;
}

.carousel-indicators [data-bs-target] {
  background-color: var(--btn-sky-blue);
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 100%;
  margin-right: 5px;
}

.carousel-indicators .active {
  background-color: var(--btn-dr-blue);
  margin-right: 5px;
  width: 13px;
  height: 13px;
  margin-top: -1.5px;
  margin-bottom: -1.5px;
}
/* Review carousel media Queries ===> No media queries for Home review carousel  */

@media only screen and (min-width: 991px) and (max-width: 1075px) {
  .rightslide-carousel-reviews-arrow-image {
    right: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rightslide-carousel-reviews-arrow-image {
    right: 10px;
  }
  .card {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .rightslide-carousel-reviews-arrow-image {
    right: 36px;
  }

  .leftslide-carousel-reviews-arrow-image {
    left: 32px;
  }
}

