.crds{
    position: relative;
    border: 3px solid transparent;
    transition: transform .2s;
    width: 24%;
    height: auto;
    padding: 25px 15px 15px ;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.main-heading-bootcamp{
    padding: 10px 0px;
}
.crds-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
  }
.card-image{
    width: 100%;
    height: auto;
    border-radius: 15px;
}
.btn-bootcamp{
    position: absolute;
    left: 6%;
    top: 2%;
}

.title-bootcamp{
    padding: 10px 0px 0px 0px;
    font-size: medium;
}
.para-bootcamp{
    font-size: small;
}
.botton-icons{
    display: inline-flex;

}
.details-card{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.profile-icon,
.star-icon{
    color: #4555d2;
    padding: 0px 5px;
}
.numb-per,
.numb-star{
    display: flex;
    padding-top: 3px;
}
.amout-course{
    display: inline-flex;
    float: right;
}

/* Hover */
.crds:hover{
    background-color: #4555d2;
    color: #ffffff;
    transform: scale(1.01);
}
.crds:hover .btn-bootcamp {
    background-color: #82cfff;
    color: #ffffff;
}
.crds:hover h6.title-bootcamp {
    color: #ffffff;
}
.crds:hover .profile-icon {
    color: #82cfff;
}
.crds:hover .star-icon {
    color: #82cfff;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
    .crds {
      width: 48%;
      margin-bottom: 20px;
    }
  }
  
  @media only screen and (max-width: 576px) {
    .crds {
      width: 100%;
    }
  }
  