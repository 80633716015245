.certificate-popup {
    margin-top: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.75); /* Zoom out to 75% */
    z-index: 999;
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close-button {
    margin-top: 0;
    margin-bottom: 10px;
    padding: 4px 16px;
    background-color: red;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 55vh;
}

.input-group {
    margin-bottom: 15px;
}
.verifypage{
    background: none;
    padding-top: 0px;
}
