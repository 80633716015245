/* Chat with us styles */
.roboto {
  position: fixed;
  right: 2%;
  bottom: 5%;
  width: 150px;
  cursor: pointer;
  z-index: 9;
}

/* Cylinder styles */
.cylinder {
  width: 100%;
  height: 55px;
  background: #4CCA5A;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 139, 0.4);
  animation: pulse 2s infinite alternate;
  transition: box-shadow 0.3s ease;
}

/* Shadow on hover */
.cylinder:hover {
  box-shadow: 0px 6px 14px rgba(0, 0, 139, 0.6);
}

/* WhatsApp icon */
.cylinder img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

/* Text inside the cylinder */
.cylinder .chat-text {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

/* Pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

/* Hide chat text on mobile devices */
@media only screen and (max-width: 768px) {
  .roboto {
    width: auto;
  }

  .cylinder .chat-text {
    display: none;
  }

  .cylinder {
    height: 50px;
    padding: 12px;
  }

  .cylinder img {
    margin-right: 0;
  }
}

/* Roboto styles */
/*.roboto {
  position: fixed;
  right: 2%;
  bottom: 5%;
  cursor: pointer;
  z-index: 9;
  background: var(--btn-sky-blue);
  box-shadow: 0px 20px 20px #1f305d12;
  border-radius: var(--box-border-radius);
}
 .roboto:hover {
  transform: scale(1.08);
  box-shadow: 0px 8px 16px rgba(0, 0, 139, 0.6);
}
.roboto-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.roboto-chat .btn {
  width: 70px;
  height: 70px;
}
.roboto-chat img {
  width: 15px;
  display: flex;
  align-items: center;
  position: relative;
  left: -2px;
  height: 100%;
}
.chat-text {
  position: absolute;
  top: calc(100% + 5px);
  left: 45%;
  transform: translateX(-50%);
  color: #333;
  font-size: 14px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
}
.roboto-chat .btn,
.roboto-chat .btn:active {
  background: var(--btn-sky-blue);
  border-radius: var(--box-border-radius);
  box-shadow: 0px 3px 20px #00000014;
} */