/*******************************/
/* Our Training Approach(OTA)  */
/*******************************/
.OTA-main-container {
  background-color: #ffffff;
  width: 80%;
  height: auto;
  margin: auto;
  padding: 5px;
  padding-bottom: 0px;
  border-radius: 30px;
  position: relative;
  top: -2.9rem;
}

.OTA-heaading-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8f2fe;
  border-radius: 30px 30px 0 0;
  height: 42px;
}

.OTA-heaading {
  background-color: #e8f2fe;
  font-family: var(--bnr-font);
  line-height: 5.5rem;
}

/* Container for the four elements with a border */
.OTA-content-wrapper {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
  gap: 10px;
  justify-content: space-around;
}

/* Styling for each individual element */
.OTA-content-wrapper-item {
  width: 20%;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
}

/* Style for the heading inside the border */
.OTA-content-wrapper-item h6 {
  margin: 30px;
  margin-top: 20px;
  font-size: 1em;
  max-width: 100%;
}

/* Style for the paragraph inside the border */
.OTA-content-wrapper-item p {
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
  max-width: 100%;
}

.OTA-content-header {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  height: 50px;
}
.content-svg {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #182457;
  border-radius: 50%;
  padding: 10px;
}
.ImageSize {
  height: 30px;
  width: 30px;
}

/* Style for the arrow displayed outside the right side of the border */
.RightArrow {
  position: absolute;
  top: 50%; 
  right: -55px; 
  transform: translateY(-50%);
}

/* Adjust the arrow size if necessary */
.RightArrowSize {
  height: 30px;
  width: 30px;
}

/***************************************/
/*   client logos block in Home page   */
/***************************************/
#clients {
  position: relative;
  height: 4.5rem;
  background: var(--bg-white-color);
}

.client-logos-block {
  padding: 1rem 1rem 1rem 0;
  margin-top: 0px;
  /* padding-top: 0px; */
  /* border-radius: var(--box-border-radius); */
  /* box-shadow: 0px 20px 20px #1f305d12; */
  background: var(--bg-white-color);
  position: relative;
  background-color: #e8f2fe;
  border-radius: 0px 0px 30px 30px;
  top: -0.4rem;
}

.client-logos-block img {
  display: inline-block;
  padding: 0px 0 0 50px;
  height: 29px;
  width: 24%;
}

.client-logos-block img:first-child {
  padding: 0;
  width: 10%;
}

.client-logos-txt {
  border-right: 1px solid var(--border-color);
  padding: 0;
}

.client-logos-txt h4 {
  font-size: var(--font-x-size);
  font-family: var(--body-font);
  font-weight: var(--font-weight-regular);
  margin: 0;
  color: var(--txt-color-Htag);
  line-height: 1.5;
}

.client-logos-txt h3 {
  font-size: var(--font-xl-size);
  margin: 0;
  color: var(--txt-color-Htag);
  line-height: 1;
}

.client-logos-txt {
  width: auto;
  padding: 0px 30px;
  display: block;
}

.client-logos {
  width: 75%;
  display: flex;
  align-items: center;
  height: 100%;
  margin: auto;
  padding: 0px 30px;
  justify-content: center;
}

  
/*** Our Training Approach(OTA) Media Queries ***/
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .OTA-main-container {
    height: auto;
  }

  .OTA-content-wrapper {
    display: flex;
    flex-direction: column;
  }

  .OTA-content-wrapper-item {
    border: 2px solid #9b9b9b;
    width: auto;
    padding: 25px;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 20px;
    height: auto;
  }

  .content-svg {
    position: absolute;
    top: -30px;
    padding: 10px;
  }

  .OTA-content-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .OTA-content-header h6 {
    position: relative;
    top: 25px;
  }
  
  .RightArrow {
    transform: rotate(90deg);
    position: absolute;
    top: 93%;
    right: calc(100%);
    left: calc(50%);
  }
}

@media (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .OTA-heaading h2{
    font-size: 1.25rem;
  }

  .OTA-main-container{
    padding-bottom: 5px;
  }
}

  /* .container .client-logos-block {
    top: 0;
    margin-top: 15px;
    padding: 20px;
  }

  .client-logos-txt {
    border: 0;
    width: 100%;
    border-bottom: 1px solid #c2c2c2;
    margin-bottom: 20px;
    padding: 0;
    justify-content: space-between;
    padding-bottom: 10px;
    text-align: center;
  }

  .client-logos-txt h3 {
    font-size: var(--font-x-size);
  }

  .client-logos {
    width: 100%;
    border: 0;
    display: grid;
    padding: 0;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .client-logos-block img {
    padding: 10px 0px;
    width: 80%;
    height: auto;
  }

  .client-logos-block img:nth-child(4),
  .client-logos-block img:last-child {
    display: block;
    position: relative;
    margin: 0 10%;
  }

  .client-logos-block img:nth-child(4),
  .client-logos-block img:last-child {
    width: 60%;
    margin: 0px 65%;
  }

  .client-logos-block img:first-child {
    width: 25%;
    height: auto;
    display: block;
    position: relative;
    margin: 0 28%;
  }
} */


@media (min-width: 481px) and (max-width: 768px) {
  body {
    overflow-x: hidden !important;
  }

  .container {
    max-width: 100% !important;
  }

  .OTA-main-container{
    padding-bottom: 5px;
  }

  .container .client-logos-block {
    padding: 0 0px 20px !important;
    top: 0px;
  }

  .client-logos-block img {
    padding: 0;
    width: 20%;
  }

  .client-logos-block img:first-child {
    width: 8%;
  }

  .client-logos {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }

  .client-logos-txt {
    padding: 20px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .OTA-content-wrapper {
    display: flex;
    height: auto;
  }

  .OTA-content-wrapper-item h6 {
    margin: 10px; 
    padding-top: 15px;
  }

  .OTA-content-wrapper-item p {
    margin: 10px; 
    padding-top: 10px;
  }
  
  .RightArrow {
    position: absolute;
    top: 50%;
    right: -35px;
    transform: translateY(-50%);
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .OTA-content-header {
    flex-direction: column;
    align-items: center;
  }

  .OTA-content-wrapper-item {
    position: relative;
  }

  .OTA-content-wrapper-item h6 {
    margin: 10px; 
    padding-top: 15px;
  }

  .OTA-content-wrapper-item p {
    margin: 10px; 
    padding-top: 10px;
  }

  .RightArrow {
    position: absolute;
    top: 50%;
    right: -35px;
    transform: translateY(-50%);
  }
}
