@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,600;1,300&display=swap");

/* Description: Master CSS file */
:root {
  --bg-color: #e7f8ff;
  --bg-gray-color: #f9fafe;
  --bg-white-color: #fff;
  --dark-blue-bg: #213a8e;
  --hover-bg: #1c3484;
  --black-bg-color: #000;
  --btn-dr-blue: #1c3484;
  --btn-sky-blue: #15a8f3;
  --btn-light-green: #62df90;
  --txt-dr-color: #182457;
  --txt-dark-blue-color: #4555d2;
  --txt-blk-color: #000;
  --txt-wht-color: #fff;
  --txt-lgt-color: #242424;
  --txt-color-Htag: #222a31;
  --des-lgt-color: #6b6b6a;
  --border-color: #dee2e6;
  --dark-blue-color: #191e47;
  --link-anchor-color: #6c717b;
  --content-bg-color: #f6f7f8;
  --dark-red-color: #ff0000;
  --bnr-font: "Space Grotesk", sans-serif;
  --body-font: "Nunito Sans", sans-serif;
  --btn-border-radius: 4px;
  --box-border-radius: 12px;
  --font-xxx-xl-size: 2.25rem;
  /* 36px */
  --font-xxxl-size: 2rem;
  /* 32px */
  --font-xxl-size: 1.75rem;
  /* 28px */
  --font-xl-size: 1.5rem;
  /* 24px */
  --font-x-size: 1.25rem;
  /* 20px */
  --font-l-size: 1.125rem;
  /* 18px */
  --font-large-size: 1rem;
  /* 16px */
  --font-medium-size: 0.875rem;
  /* 14px  */
  --font-small-size: 0.75rem;
  /* 12px */
  --line-height-h1: 1.25;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;
}

/**************************/
/*     General Styles     */
/**************************/
body,
html {
  width: 100%;
  height: 100%;
}

body {
  background: var(--bg-color);
  color: var(--txt-lgt-color);
  font-family: var(--body-font);
}

h1 {
  color: var(--dark-blue-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-xxl-size);
  line-height: 3.25rem;
  letter-spacing: -0.4px;
}

h2 {
  color: var(--dark-blue-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-xxl-size);
  line-height: var(--font-xxl-size);
  letter-spacing: -0.2px;
  margin: 0;
}

h3 {
  color: var(--dark-blue-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-xl-size);
  line-height: 2.25rem;
  letter-spacing: -0.2px;
}

h4 {
  color: var(--txt-color-Htag);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-xl-size);
  line-height: 1;
}

h5 {
  color: var(--dark-blue-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-x-size);
  line-height: 1.625rem;
}

h6 {
  color: var(--txt-lgt-color);
  font-size: var(--font-medium-size);
  font-weight: var(--font-weight-semibold);
}

.h3-medium {
  color: var(--txt-color-Htag);
  font-size: var(--font-xxl-size);
  font-family: var(--bnr-font);
}

.p-large {
  font-size: var(--font-l-size);
  line-height: 1.75rem;
}

.p-small {
  font-size: var(--font-medium-size);
  line-height: 1.5rem;
}

.testimonial-text {
  font-style: italic;
}

.testimonial-author {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-x-size);
  line-height: 1.75rem;
}

.li-space-lg li {
  margin-bottom: 0.5rem;
}

a {
  color: var(--link-anchor-color);
  text-decoration: underline;
}

a:hover {
  color: var(--link-anchor-color);
  text-decoration: none;
}

.no-line {
  text-decoration: none;
}

.no-line:hover {
  text-decoration: none;
}

.blue {
  color: var(--txt-dark-blue-color);
}

.bg-gray {
  background-color: var(--bg-gray-color);
}

.btn,
.btn-sky-blue {
  background: var(--btn-dr-blue);
  color: var(--txt-wht-color);
  font-family: var(--btn-font);
  border-radius: var(--btn-border-radius);
  font-size: var(--font-medium-size);
}

.btn:hover,
.btn-sky-blue:hover {
  background: var(--btn-sky-blue);
  color: var(--txt-wht-color);
  border: 1px solid var(--btn-sky-blue);
}

.btn-sky-blue {
  background: var(--btn-dr-blue);
}

.btn-sky-blue:hover {
  background: var(--btn-sky-blue);
}

.btn:disabled {
  background: var(--link-anchor-color);
  cursor: not-allowed;
  opacity: 0.5;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 1s linear infinite;
}

.btn-solid-reg {
  display: inline-block;
  padding: 1.375rem 2.25rem 1.375rem 2.25rem;
  border: 1px solid var(--txt-dark-blue-color);
  border-radius: var(--box-border-radius);
  background-color: var(--txt-dark-blue-color);
  color: var(--txt-wht-color);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-medium-size);
  line-height: 0;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-solid-reg:hover {
  background-color: transparent;
  color: var(--txt-dark-blue-color);
  /* needs to stay here because of the color property of a tag */
  text-decoration: none;
}

.btn-solid-lg {
  display: inline-block;
  padding: 1.625rem 2.625rem 1.625rem 2.625rem;
  border: 1px solid var(--txt-dark-blue-color);
  border-radius: var(--box-border-radius);
  background-color: var(--txt-dark-blue-color);
  color: var(--txt-wht-color);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-medium-size);
  line-height: 0;
  text-decoration: none;
  transition: all 0.2s;
}

.btn-solid-lg:hover {
  background-color: transparent;
  color: var(--txt-dark-blue-color);
  /* needs to stay here because of the color property of a tag */
  text-decoration: none;
}

.btn-none {
  border: none;
  background: none;
  padding: 0 15px;
}

/* button[aria-expanded="false"] .navbar-toggler-icon {
  display: block;
}

button[aria-expanded="true"] .navbar-toggler-icon {
  display: none;
}

.close-icon {
  font-size: 28px;
  line-height: 0;
}

button[aria-expanded="false"] .close-icon {
  display: none;
}

button[aria-expanded="true"] .close-icon {
  display: block;
} */

.form-group {
  position: relative;
  margin-bottom: 1.25rem;
}

.form-group .span1 {
  font-size: 10px;
}

.form-control-input,
.form-control-textarea {
  width: 100%;
  /* padding-top: 0.875rem;
	padding-bottom: 0.875rem; */
  padding-left: 12px;
  border: 1px solid #cbcbd1;
  border-radius: var(--box-border-radius);
  background-color: var(--bg-white-color);
  font-size: var(--font-medium-size);
  line-height: 1.5rem;
  -webkit-appearance: none;
  appearance: none;
  /* removes inner shadow on form inputs on ios safari */
}

.form-control-textarea {
  display: block;
  height: 14rem;
  /* used instead of html rows to normalize height between Chrome and IE/FF */
}

.form-control-input:focus,
.form-control-textarea:focus {
  border: 1px solid var(--border-color);
  outline: none;
  /* Removes blue border on focus */
}

.form-control-input:hover,
.form-control-textarea:hover {
  border: 1px solid var(--border-color);
}

.form-control-submit-button {
  display: inline-block;
  width: 100%;
  height: 3.25rem;
  border: 1px solid var(--txt-dark-blue-color);
  border-radius: var(--box-border-radius);
  background-color: var(--txt-dark-blue-color);
  color: var(--txt-wht-color);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-medium-size);
  line-height: 0;
  cursor: pointer;
  transition: all 0.2s;
}

.form-control-submit-button:hover {
  border: 1px solid var(--txt-dark-blue-color);
  background-color: transparent;
  color: var(--txt-dark-blue-color);
}

.banner-input-field {
  width: 70%;
  position: relative;
  /* animation: fadeInUpBig 2000ms ease 1000ms backwards; */
}

.banner-input-field input {
  height: 40px;
  border-radius: var(--btn-border-radius);
  box-shadow: 0px 10px 10px #00000012;
}

.banner-input-field .btn-cut {
  position: absolute;
  right: 0;
  border-radius: 0 4px 4px 0;
  padding: 8px 25px;
  top: 0;
  height: 40px;
}

/* .banner-tick {
  animation: fadeInLeft 1200ms ease 500ms backwards;
} */

.banner_button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-size: var(--font-large-size);
  font-weight: var(--font-weight-semibold);
  color: var(--btn-dr-blue);
}

.banner_button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-size: var(--font-large-size);
  font-weight: var(--font-weight-semibold);
  color: var(--btn-dr-blue);
}

.banner-tick ul {
  margin: 25px 0 0;
  padding: 0;
}

.banner-tick li {
  background: url(../images/banner-tick.svg) no-repeat;
  list-style: none;
  line-height: 50px;
  padding-left: 50px;
  font-size: var(--font-large-size);
  padding-bottom: 0;
  font-weight: var(--font-weight-semibold);
  background-position: left center;
  margin-bottom: 0px;
}

.video-btn button {
  width: 7.3rem;
  height: 65px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 42.3%;
  background: transparent;
  border: none;
  color: transparent;
  border-radius: 60px;
}

.video-btn button:hover {
  border: 0;
}

.video-btn button.btn:hover,
.video-btn button.btn:focus,
.video-btn button.btn:active {
  background: none;
  color: transparent;
}

.video-btn {
  position: relative;
}

.modal-content,
.modal-header {
  border-bottom: 0;
  background: var(--black-bg-color);
  padding: 0;
}

.modal-dialog {
  max-width: 70%;
}

.modal-body {
  padding: 0 0 0.5rem 0;
}

/* Banner carousel */
#bannerCarousel {
  min-width: auto;
  min-height: auto;
  width: 60%;
  max-width: 100%;
  height: auto;
  position: relative;
  border: 1.5px solid var(--btn-sky-blue);
  background: var(--bg-white-color);
  border-radius: var(--box-border-radius);
  box-shadow: 0px 50px 99px #1f305d42;
  bottom: 25px;
  margin-top: 20px;
  left: 50px;
  /* animation: fadeInRight 1600ms ease 400ms backwards; */
}

/* Adjust the carousel item padding and height */
#bannerCarousel .carousel-item {
  padding: 10px;
  height: auto;
}

/* Adjust the size of the circular images */
#bannerCarousel .carousel-item .rounded-circle {
  width: 35%;
  height: 35%;
  position: relative;
  top: 0px;
  left: 0px;
}

/* Adjust the position of the carousel caption */
#bannerCarousel .carousel-caption {
  position: absolute;
  text-align: center;
  top: 0px;
  left: 90px;
  right: 0px;
  width: auto;
  padding: 10px;
}

/* Style carousel item text */
#bannerCarousel .carousel-item h6 {
  font-size: var(--font-large-size);
  color: var(--txt-lgt-color);
}

#bannerCarousel small {
  font-size: var(--font-large-size);
  color: var(--des-lgt-color);
}

/* Adjust the position of the See more button */
.seemore-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 50px;
  background-color: var(--btn-dr-blue);
  font-size: var(--font-medium-size);
}

.seemore-btn a {
  color: #fff;
  text-decoration: none;
}

#bannerCarousel .carousel-indicators {
  bottom: -40px;
}

#bannerCarousel .carousel-indicators [data-bs-target] {
  background-color: var(--btn-sky-blue);
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 100%;
  margin-right: 5px;
}

#bannerCarousel .carousel-indicators .active {
  background-color: var(--btn-dr-blue);
  margin-right: 5px;
  width: 13px;
  height: 13px;
  margin-top: -1.5px;
  margin-bottom: -1.5px;
}

.batches-btn {
  margin: 0 15px 0;
  position: absolute;
  bottom: 25px;
  padding: 5px 15px;
  left: 20px;
  font-size: var(--font-medium-size);
}

.batches-btn a {
  color: #fff;
  text-decoration: none;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

/**********************/
/*     Navigation     */
/**********************/
.navbar {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-medium-size);
  line-height: 0.875rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  padding: 10px 0px;
}

.navbar .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}

.navbar .navbar-brand img {
  height: 40px;
  width: 100%;
}

.navbar .logo-text {
  color: var(--dark-blue-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-xxl-size);
  line-height: 1rem;
  text-decoration: none;
}

.offcanvas-collapse {
  position: fixed;
  top: 3.25rem;
  /* adjusts the height between the top of the page and the offcanvas menu */
  bottom: 0;
  left: 100%;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  overflow-y: auto;
  visibility: hidden;
  background-color: var(--bg-white-color);
  transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
}

.offcanvas-collapse.open {
  visibility: visible;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.navbar .navbar-nav {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

span.nav-item .btn {
  font-size: var(--font-medium-size);
  line-height: 1;
  padding: 10px 12px;
}

.navbar .nav-item .nav-link {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  color: var(--txt-blk-color);
  text-decoration: none;
  font-size: var(--font-large-size);
  transition: all 0.2s ease;
  font-size: var(--font-medium-size);
}

.navbar .nav-item.dropdown.show .nav-link,
.navbar .nav-item .nav-link:hover {
  color: var(--txt-dr-color);
}

.navbar .nav-item .nav-link.active {
  color: var(--btn-sky-blue);
  font-weight: var(--font-weight-semibold);
}

/* Dropdown Menu */
.navbar .dropdown .dropdown-menu {
  animation: fadeDropdown 0.2s;
  /* required for the fade animation */
}

@keyframes fadeDropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navbar .dropdown-menu {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  border: none;
  background-color: var(--bg-white-color);
}

.navbar .dropdown-item {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  color: var(--dark-blue-color);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-medium-size);
  line-height: 0.875rem;
  text-decoration: none;
}

.navbar .dropdown-item:hover {
  background-color: var(--bg-white-color);
  color: var(--txt-dark-blue-color);
}

.navbar .dropdown-divider {
  width: 100%;
  height: 1px;
  margin: 0.5rem auto 0.5rem auto;
  border: none;
  background-color: var(--border-color);
}

/* end of dropdown menu */

.navbar .navbar-toggler {
  padding: 0;
  border: none;
  font-size: var(--font-x-size);
}

/*****************/
/*    Header     */
/*****************/
.header {
  position: relative;
  overflow: hidden;
  padding-top: 4rem;
  padding-bottom: 4em;
  background: url(../images/header_bg_new.jpg) no-repeat;
  background-size: 100% 100%;
  text-align: center;
}

.header .decoration-star {
  position: absolute;
  top: 80px;
  left: -100px;
  width: 200px;
  opacity: 0.7;
}

.header .decoration-star-2 {
  position: absolute;
  top: 80px;
  right: -100px;
  width: 200px;
  opacity: 0.7;
}

.header div[class*="col"] {
  /* selects all elements which have classes starting with col */
  /* needed so that the absolute positioned decoration stays behind */
  position: relative;
}

.header {
  padding-top: 4rem;
}

.header .h1-large {
  margin: 0px;
  font-size: var(--font-xxxl-size);
  font-family: var(--bnr-font);
  color: var(--txt-lgt-color);
}

.header .h1-large {
  width: 80%;
  line-height: var(--line-height-h1);
}

.header .p-large {
  width: 80%;
  font-size: var(--font-large-size);
  line-height: 1.5;
  color: var(--des-lgt-color);
  padding: 20px 0px;
  margin: 0px;
}

.header .btn-solid-lg,
.header .btn-outline-lg {
  margin-right: 0.25rem;
  margin-bottom: 1.25rem;
  margin-left: 0.25rem;
}

.header .image-container {
  margin-top: 5rem;
}

header#JavaHomeHeader .text-container {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.dev-tag {
  margin-bottom: 15px;
}

.h1-large {
  font-size: var(--font-xxxl-size);
  line-height: var(--line-height-h1);
  font-family: var(--bnr-font);
  color: var(--txt-lgt-color);
}

.course-detail-heading .h1-large {
  font-size: var(--font-xxl-size);
}

.course-detail-heading h2 {
  padding: 20px 0px 0px;
}

.des-txt {
  /* font-size: var(--font-large-size); */
  line-height: 21px !important;
  font-size: 14px;
  font-weight: normal;
  padding-top: 0px;
  padding-bottom: 0px;
  color: var(--des-lgt-color);
  margin-bottom: 0;
}

.up-coming-batches-main {
  height: auto;
  width: 90%;
  margin: auto;
  position: relative;
  top: -75px;
  background-color: #fff;
  border-radius: var(--box-border-radius);
  border: none;
  box-shadow: 0px 20px 20px #1f305d12;
  border-radius: var(--box-border-radius);
  padding: 44px 32px 20px 32px;
}

/* #JavaHomeHeader .h1-large {
  animation: fadeInLeft 2s ease 500ms backwards;
}
#JavaHomeHeader .p-large {
  animation: fadeInLeft 1s ease 200ms backwards;
}
.b-img {
  animation: fadeInRightBig 1s ease 200ms backwards;
} */
/* .cards-2 .des-txt {
  padding-left: 8%;
} */
/* .browse-blc {
  padding-top: 3%;
} */
.browse-blc a {
  float: right;
}

/**********************/
/*     Statistics     */
/**********************/
.counter-container {
  display: flex;
  justify-content: space-between;
}

.counter-container .counter-cell:first-child {
  margin-left: 0px;
}

.counter-container .counter-cell:last-child {
  margin-right: 0px;
}

.counter {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  background: var(--bg-white-color);
}

.counter .counter-cell {
  margin-bottom: 0;
}

.counter .purecounter {
  margin-bottom: 10px;
  color: var(--dark-blue-color);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-xxl-size);
  color: var(--btn-sky-blue);
  font-weight: var(--font-weight-semibold);
  font-family: var(--bnr-font);
}

.counter .counter-info {
  color: var(--txt-lgt-color);
  font-size: var(--font-large-size);
  line-height: 1.5rem;
  font-weight: var(--font-weight-semibold);
}

/************************/
/*     Most popular teacher css     */
/************************/
.most-popular-teacher {
  background: url(../images/most-popular-teacher.png) no-repeat;
  background-size: cover;
  background-position: 0% 16%;
  height: 360px;
}

.star-review-bg img {
  width: 100px;
  display: inline-flex;
}

.most-popular-content {
  /* background: url(../images/most-popular-teacher.png) no-repeat;
	background-size: cover; */
  padding: 5% 0px;
}

.most-popular-content .text-container {
  padding-left: 25%;
}

.text-container .des-txt {
  margin: 30px 0px;
}
.sub-title {
  color: var(--txt-lgt-color);
}

/* .text-container h2 {
  width: 80%;
} */
.star-review-bg {
  text-align: center;
}

.star-review-bg h4 {
  padding-top: 4%;
}

/************************/
/*     Sections css     */
/************************/
.icons-bg {
  background: var(--bg-white-color);
  height: 35px;
  width: 35px;
  border-radius: 100%;
  border: 1px solid var(--btn-sky-blue);
  display: flex;
  align-items: center;
  justify-content: center;
}

/************************/
/*     Introduction     */
/************************/
.basic-1 {
  padding-top: 9.25rem;
  padding-bottom: 8.75rem;
}

.basic-1 h2 {
  margin-bottom: 1.75rem;
}

/*********************/
/*     Common section 1     */
/*********************/
.common-section {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
}

.center_align_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px !important;
}

.review-main {
  position: relative;
  top: -8px;
}

.review-main .image-container img {
  width: 70%;
}

.access-courses .course_points {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.access-courses .banner-tick li {
  line-height: 45px;
}

.review-bg {
  background: url(../images/review.svg) no-repeat;
  width: 188px;
  height: 147px;
  position: absolute;
  right: 15%;
  text-align: center;
  padding-top: 20px;
}

.cards-1 .h1-large {
  position: relative;
}

.text-container {
  position: relative;
}

.h1-bg {
  position: absolute;
  right: 43%;
  top: -43%;
}

.review-girl {
  width: 75%;
}

.rate {
  float: left;
  height: 46px;
  padding: 35px 0 0 60px;
}

.rate h6 {
  position: absolute;
  font-size: var(--font-medium-size);
  left: 0;
  bottom: 10px;
  margin: 0;
  font-weight: var(--font-weight-bold);
}

.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: var(--font-xl-size);
  color: var(--border-color);
}

.rate:not(:checked) > label:before {
  content: "★ ";
}

.rate > input:checked ~ label {
  color: var(--btn-sky-blue);
}

.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: var(--btn-sky-blue);
}

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: var(--btn-sky-blue);
}

.cards-2 {
  padding: 30px 0px;
}

.course-white-b {
  position: relative;
  top: -30px;
}

.course-white-b img {
  width: 90%;
  display: block;
  margin: 0 auto;
}

.course-box-item {
  width: 62%;
  max-width: 355px;
  height: 90px;
  background: var(--bg-white-color);
  position: absolute;
  border: 1.5px solid var(--btn-sky-blue);
  border-radius: var(--box-border-radius);
  box-shadow: 0px 50px 99px #1f305d42;
  left: 80px;
  bottom: -7px;
}

.blue-circle {
  width: 50px;
  height: 50px;
  background-color: var(--btn-sky-blue);
  border-radius: 100%;
  position: relative;
  cursor: pointer;
}

.blue-circle span {
  display: inline-block;
  border-left: 18px solid var(--bg-white-color);
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  position: absolute;
  top: 26%;
  left: 39%;
}

.course-align-items {
  padding-left: 20px;
}

.course-align-items .text-course {
  font-size: var(--font-large-size);
  margin: 0;
}

.course-align-items .text-black {
  margin-bottom: 0;
  font-size: var(--font-medium-size);
}

/**** JHC youtube_player video css in Home page ****/
.modal.youtube_player .modal-content,
.modal.youtube_player .modal-body > div,
.modal.youtube_player .modal-body > div > div {
  max-width: 90%;
  max-height: 100vh;
  margin: 0 auto;
}

.modal.youtube_player .modal-content {
  background: transparent;
  border: none;
}

.modal.youtube_player .modal-body {
  padding: 0;
}

.rounded-video-wrapper {
  border-radius: 5px;
  overflow: hidden;
}

/****************************************/
/*     Social activites section css     */
/****************************************/
.social-page h1 {
  padding-top: 24px;
}

.social-activities {
  padding: 40px 0px 16px;
}

.social-a {
  box-shadow: 0px 20px 20px #1f305d12;
  animation: scale-down 0.4s ease;
  cursor: pointer;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  border: 2px solid var(--btn-sky-blue);
}

.social-a:hover {
  transform: scale(1.02);
}

.social-a .social-a-title {
  background: var(--bg-white-color);
  padding: 20px;
  font-size: var(--font-large-size);
  line-height: var(--line-height-h1);
  color: var(--txt-color-Htag);
  font-family: var(--bnr-font);
  border-radius: 0px 0px 10px 10px;
  text-transform: capitalize;
}

/*******************************************/
/*     Specialized Services css in Home    */
/*******************************************/
.specialized-services {
  background: #9cdcfc;
  padding: 40px 20px;
}

.specialized-services .text-container {
  text-align: center;
}

.text-container-heading {
  padding: 0px 20px;
}

.specialized-services {
  font-weight: var(--font-weight-semibold);
  line-height: 36px;
  /* margin: 15px 0px; */
}

.service-card {
  background: var(--bg-white-color);
  padding: 40px 40px;
  border-radius: var(--box-border-radius);
  border: 1px solid var(--btn-sky-blue);
  text-align: center;
  box-shadow: 0px 20px 20px #1f305d12;
  cursor: pointer;
  animation: scale-down 0.4s ease;
  height: 100%;
  line-height: 20px;
}

.service-card a {
  text-decoration: none;
}

.service-card:hover {
  transform: scale(1.02);
}

.service-card .class-h {
  font-size: var(--font-x-size);
  color: var(--txt-lgt-color);
  padding: 15px 0px;
  margin: 0px;
}

.service-card p {
  font-size: var(--font-medium-size);
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;*/
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--des-lgt-color);
}

.cards-3 {
  padding: 0 0 20px;
}

.cards-3 .h1-large {
  font-size: var(--font-xxl-size);
}

.post-img img {
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.content-bg-color {
  background: var(--content-bg-color);
}

/******************************/
/*     Home page Tabs css     */
/******************************/
/* .tabes-panel {
  padding-bottom: 80px;
} */
.tabs {
  padding: 0 0 15px 0;
  border: none;
  background: none;
  font-size: var(--font-x-size);
  color: var(--des-lgt-color);
  margin-right: 35px;
  /* transition: 3s ease-in; */
}

.tabs:hover {
  color: var(--txt-dr-color);
  /* transition: all 0.5s; */
  /* transform: translateX(-10px); */
}

.tabs:before {
  content: "";
  width: 0;
  height: 2px;
  background-color: var(--txt-dr-color);
  position: absolute;
  top: 100%;
  left: 0;
  /* transition: all 0.5s;*/
}

.tabs:after {
  content: "";
  width: 0;
  height: 2px;
  /* background-color: var(--btn-sky-blue); */
  position: absolute;
  top: 100%;
  right: 0;
  transition: all 0.5s;
}

/* .tabs:hover:before {
  width: 50%;
  transform: translateX(100%);
}

.tabs:hover:after {
  width: 50%;
  transform: translateX(-100%);
} */
.tabs:last-child {
  margin-right: 0;
}

.tabs-nav {
  padding: 15px 25px 0 25px;
  background: var(--bg-white-color);
  border-radius: var(--btn-border-radius);
  box-shadow: 0px 20px 20px #1f305d12;
  display: flex;
  align-items: center;
  width: 1190px;
}

.active-tab {
  color: var(--txt-dr-color);
  border-bottom: 3px solid var(--txt-dr-color);
  font-weight: var(--font-weight-semibold);
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  /* transform: translateX(-50%); */
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(88, 147, 241);
}

.content {
  display: none;
}

.content h2 {
  padding: 0px 0 5px 0px;
}

.content p {
  width: 100%;
}

.active-content {
  display: block;
}

.content-tabs {
  padding-top: 20px;
}

.tab-item {
  background: var(--bg-white-color);
  padding: 35px 15px 15px;
  border-radius: var(--box-border-radius);
  margin-bottom: 15px;
  box-shadow: 0px 3px 20px #00000014;
  max-height: 400px;
}

.tab-item:hover {
  background: var(--hover-bg);
  color: var(--txt-wht-color);
  cursor: pointer;
}

.tab-item:hover .tab-h6-left h6,
.tab-item:hover .tab-content-des h6,
.tab-item:hover .tab-content-des p,
.tab-item:hover .tabs-flex span {
  color: var(--txt-wht-color);
}

.tabs-class-heading1 {
  position: relative;
  padding: 0;
}

.tabs-class-heading1 .tab-course-name-H {
  position: absolute;
  background: var(--dark-blue-bg);
  padding: 10px;
  color: var(--txt-wht-color);
  border-radius: var(--btn-border-radius);
  font-size: var(--font-medium-size);
  top: -20px;
}

.tab-item:hover .tabs-class-heading1 .tab-course-name-H {
  background: var(--btn-sky-blue);
}

.tabs-class-heading2 {
  overflow: hidden;
  padding: 30px 0 0;
}

.tab-h-left {
  float: left;
}

.tab-p-right {
  float: right;
}

.tab-p-right span {
  padding-right: 20px;
  font-size: var(--font-xl-size);
}

.tabs-currency {
  font-size: var(--font-medium-size);
  font-weight: var(--font-weight-semibold);
  margin: 0;
  line-height: 32px;
}

.tab-content-des {
  padding-top: 20px;
  /* border-bottom: 1px solid var(--border-color); */
  padding-bottom: 20px;
}

.tab-content-des h6 {
  font-size: var(--font-x-size);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.tab-content-des p {
  padding-top: 0px;
  font-size: var(--font-medium-size);
  color: var(--des-lgt-color);
  margin-bottom: 0;
}

.tabs-flex p {
  width: auto;
  color: var(--btn-sky-blue);
  font-size: var(--font-x-size);
}

.tabs-flex span {
  color: var(--txt-blk-color);
  font-size: var(--font-medium-size);
  padding-left: 10px;
  display: inline-block;
}

/***************************/
/*     Course page css     */
/***************************/

.sub-banner,
.course-detail-sub-banner {
  min-height: 18em;
  height: auto;
  /* max-height: fit-content; */
  padding-top: 100px;
  text-align: center;
  background: url(../images/header_bg.png) no-repeat;
  background-size: cover;
}

.course-page .tabes-panel {
  background: var(--bg-white-color);
  padding-bottom: 20px;
}

.access-courses {
  padding-bottom: 100px;
  padding-top: 50px;
}

.course-details {
  background: var(--bg-white-color);
  scroll-margin-top: 130px;
}

.course-details .des-txt {
  text-align: left;
  padding-bottom: 50px;
}

.course-tabs .cts-c {
  position: relative;
}

.course-tabs .tabs-nav {
  position: relative;
  top: -90px;
  width: 1190px;
}

.course-tabs .content-tabs {
  margin-top: 0px;
}

.search-course {
  position: absolute;
  top: 30px;
  right: 0px;
}

.search-input-box {
  width: 293px;
  height: 36px;
  border-radius: var(--btn-border-radius);
  border: 0;
  color: var(--des-lgt-color);
  padding: 7px 35px 7px 15px;
  border: 1px solid var(--border-color);
  background: var(--bg-white-color);
  font-weight: var(--font-weight-regular);
}

.search-course span {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  color: var(--btn-sky-blue);
  padding: 5px 15px;
}

.upcoming-schedule-training {
  padding: 44px 32px 20px 32px;
  background: var(--bg-white-color);
  text-align: center;
  position: relative;
  top: -65px;
  box-shadow: 0px 20px 20px #1f305d12;
  border-radius: var(--box-border-radius);
}

.upcoming-batch table td {
  vertical-align: middle;
  padding: 10px 20px;
  font-size: var(--font-medium-size);
  text-align: center;
}

.upcoming-batch table td.c-td {
  text-align: left;
}

.upcoming-batch .des-txt {
  padding-bottom: 30px;
  font-size: var(--font-medium-size);
}

.upcoming-batch table td.c-td .btn.float-end {
  position: relative;
  top: -15px;
  right: 30px;
}

.upcoming-batch table .c-week,
.upcoming-batch table .c-time {
  display: block;
}

.upcoming-batch table .c-week-day {
  color: var(--dark-blue-bg);
  padding-top: 5px;
  display: inline-block;
}

.upcoming-batch table .c-session {
  color: var(--dark-red-color);
  padding-top: 5px;
  display: inline-block;
}

.upcoming-batch h2 {
  padding-bottom: 20px;
}

.r-batch-btn {
  margin-left: 10px;
}

/*******************/
/*     Course details page     */
/*******************/
.course-detail-sub-banner {
  padding-top: 60px;
  /* height: auto; */
  padding-bottom: 0px;
}

.course-detail-sub-banner {
  text-align: left;
}

.course-detail-heading nav {
  padding-top: 30px;
}

.course-detail-heading .des-txt {
  padding: 10px 0px;
}

.course-ctg {
  background: var(--bg-white-color);
  padding: 30px;
  padding-bottom: 75px;
}
.btns-courses {
  display: flex;
  justify-content: end;
}

.course-dt h6 {
  color: var(--des-lgt-color);
  font-size: var(--font-medium-size);
  font-weight: var(--font-weight-regular);
  margin-bottom: 10px;
}

.course-dt h4 {
  color: var(--txt-color-Htag);
  font-size: var(--font-large-size);
  font-weight: var(--font-weight-semibold);
}

.course-dt .review-main svg {
  margin-right: 10px;
}

.course-dt.course-rv {
  padding-left: 15px;
}

.breadcrumb-item {
  font-size: var(--font-medium-size);
  position: relative;
  padding-right: 20px;
}

.breadcrumb-item a {
  font-size: var(--font-medium-size);
  color: var(--btn-sky-blue);
  text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--btn-sky-blue);
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 9px;
  border-bottom: 1.5px solid;
  border-right: 1.5px solid;
  transform: rotate(-45deg);
  top: 6px;
  left: -14px;
}

/* .placement-teacher-details {
  width: 80%;
} */
.placement-banner-img {
  text-align: right;
  display: block;
}

.placement-banner-img img {
  width: 60%;
  height: 12em;
  position: absolute;
  top: 15%;
  right: 0;
  border-radius: 10px;
}

.placement-banner-img.video-btn {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.placement-banner-img.video-btn button {
  bottom: -14px;
  top: auto;
  width: 127px;
  right: 18px;
  cursor: pointer;
  z-index: 9;
}

.course-details-tabs {
  background: var(--content-bg-color);
}

.course-details-tabs .content-tabs {
  margin-top: 0px;
  padding: 0;
}

.course-details-tabs .navbar {
  padding: 0;
}

.course-details-tabs .tabs-nav {
  width: 100%;
  border: 1px solid var(--border-color);
  background: var(--bg-white-color) 0% 0% no-repeat padding-box;
  padding: 11px 15px 15px 20px;
  border-radius: var(--box-border-radius);
  box-shadow: 0px 0px 20px #1f305d0d;
  position: relative;
  top: -40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0px;
}

.course-details-tabs .tabs-nav .tabs {
  border-right: 1px solid var(--border-color);
  padding: 0px;
  border-top: 1px solid var(--bg-white-color);
  border-bottom: 1px solid var(--bg-white-color);
  margin-right: 0;
  transform: none;
  transition: none;
  position: relative;
  width: 90%;
}

.course-details-tabs .tabs-nav .tabs:last-child {
  border-right: 0;
}

.course-details-tabs .tabs-nav .tabs:after,
.course-details-tabs .tabs-nav .tabs:before {
  bottom: -17px;
  top: auto;
}

/* .course-details-tabs .tabs-nav .tabs.active-tab:hover::before,
.course-details-tabs .tabs-nav .tabs.active-tab:hover::after {
  border-bottom: 0;
  height: 0;
} */
.course-details-tabs .tabs-nav .tabs.active-tab::after {
  border-bottom: 2px solid var(--btn-dr-blue);
  content: "";
  width: 70%;
  height: 2px;
  display: block;
  position: absolute;
  left: 28px;
  transition: none;
  bottom: -16px;
  right: auto;
  top: auto;
}

.course-content {
  padding-bottom: 30px;
}

.course-content-1 {
  padding-bottom: 35px;
}

.course-content-1:last-child {
  padding-bottom: 0;
}

.course-content-heading {
  font-size: var(--font-xl-size);
  font-weight: var(--font-weight-semibold);
  color: var(--txt-blk-color);
  margin-bottom: 15px;
}

.course-content-1 p {
  font-size: var(--font-large-size);
  margin-bottom: 0;
  color: var(--des-lgt-color);
  line-height: 25px;
  text-align: justify;
}

.course-content-1 ul {
  padding: 5px 0px 0px;
}

.course-content,
.course-content-1 li {
  padding-bottom: 0;
}

.course-content-1 li,
.common-block li {
  list-style: none;
  padding-bottom: 5px;
  font-size: var(--font-large-size);
  position: relative;
  padding-left: 25px;
  color: var(--des-lgt-color);
}

.course-content-1 li::before,
.common-block li::before {
  width: 12px;
  height: 12px;
  content: "";
  display: inline-block;
  background: var(--btn-sky-blue);
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 5px;
}

.course-download-file {
  width: 50%;
  float: right;
  position: relative;
}

.button-float-right {
  border: 1px solid var(--border-color);
  background: var(--bg-white-color) 0% 0% no-repeat padding-box;
  padding: 11px 12px;
  border-radius: var(--box-border-radius);
  box-shadow: 0px 0px 20px #1f305d0d;
  position: absolute;
  right: 0;
  top: -40px;
}

.button-float-right .btn {
  padding: 6.5px 12px;
  font-size: var(--font-medium-size);
}

/*ProgressBar */
.progress_rating {
  border: 0.5px solid var(--border-color);
  border-radius: var(--box-border-radius);
  margin-bottom: 20px;
  padding: 15px;
  align-items: center;
  background: var(--bg-white-color);
  box-shadow: 0px 0px 20px #1f305d0d;
}

.progress_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  top: -15px;
}

.bar-container {
  flex-wrap: wrap;
  width: 92%;
  margin: 0 0 0 auto;
}

.bar-container:last-of-type {
  margin-bottom: 0;
}

.progress-bar {
  position: relative;
  width: 92%;
  height: 10px;
  background-color: #e4e4e4;
  border-radius: var(--box-border-radius);
  overflow: initial;
}

.filler {
  height: 100%;
  border-radius: var(--box-border-radius);
  background-color: var(--btn-sky-blue);
}

.percentage {
  position: absolute;
  right: -50px;
  height: 100%;
  border-radius: var(--box-border-radius);
  color: var(--des-lgt-color);
  display: flex;
  align-items: center;
  font-size: var(--font-large-size);
}

.content .skill-title {
  margin: 0;
  color: var(--txt-color-Htag);
  position: relative;
  left: -80px;
  top: 20px;
  width: auto;
  font-size: var(--font-large-size);
  font-weight: var(--font-weight-semibold);
}

.rating_section {
  border: 9px solid var(--btn-sky-blue);
  border-radius: var(--box-border-radius);
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
}

.rating_count {
  font-size: 70px;
  color: var(--txt-color-Htag);
  line-height: 1;
}

.comment_container {
  border: 1px solid var(--border-color);
  border-radius: var(--box-border-radius);
  margin-bottom: 30px;
  padding: 15px;
  display: inline-block;
  width: 100%;
  background: var(--bg-white-color);
}

.rate_us {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.message_sec {
  padding-left: 0;
}

.message_sec textarea.form-message.form-control {
  height: 95px;
}

.rate_heading {
  font-size: var(--font-xxl-size);
  color: var(--txt-blk-color);
  padding-right: 20px;
  font-weight: var(--font-weight-semibold);
  line-height: 1;
}

.comment_box .form-container {
  background-color: transparent;
  padding: 0;
}

.comment_box span {
  font-size: var(--font-l-size);
  color: var(--txt-blk-color);
  font-weight: var(--font-weight-semibold);
  padding: 5px 0px 5px 0px;
  display: block;
}

.form-container .input_sec {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}

.form-container .post_btn {
  min-width: 100px;
  height: 36px;
  padding: 7px 20px;
  text-align: center;
  justify-content: center;
}

/* Tab Accordions */
/* .tab-accordions {
  padding-bottom: 30px;
} */

.tab-accord {
  background: var(--bg-white-color);
  position: relative;
  padding: 16px 20px;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: var(--btn-border-radius);
  margin-bottom: 5px;
}

.common-crs-details {
  background: var(--bg-white-color);
  position: relative;
  padding: 0;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: var(--btn-border-radius);
  margin-bottom: 5px;
}

.tab-accord h2,
.common-crs-details h2 {
  color: var(--txt-blk-color);
  font-size: var(--font-x-size);
  font-weight: var(--font-weight-semibold);
  padding: 0;
  margin: 0;
}

.tab-accordions .search-course {
  top: 12px;
  right: 15px;
}

.tab-accordions .search-input-box {
  border: 1px solid var(--border-color);
  background: var(--bg-white-color);
}

.tab-accordions .accordion-button:not(.collapsed) {
  color: var(--txt-color-Htag);
  background: none;
  box-shadow: none;
  padding: 0;
}

.tab-accordions .accordion-item .accordion-button {
  padding: 0px;
  display: flex;
  align-items: center;
  position: relative;
}

.tab-accordions .accordion-item .accordion-button h5 {
  margin: 0px 10px 0px 0px;
}

.tab-accordions .accordion-item .accordion-button:before {
  color: var(--btn-sky-blue);
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-135deg);
  top: 12px;
  left: auto;
  right: 0px;
  transition: var(--bs-accordion-btn-icon-transition);
}

.tab-accordions.faq-accordions {
  padding: 0;
}

.tab-accordions.faq-accordions .accordion-item .accordion-button:before {
  left: 0;
  right: auto;
}

.tab-accordions.faq-accordions .accordion-item .accordion-button h5 {
  margin: 0;
  padding-left: 25px;
}

.tab-accordions .accordion-item .accordion-button.collapsed::before {
  transform: rotate(45deg);
  top: 8px;
}

.tab-accordions .accordion-item .accordion-button h5,
.common-crs-details h5 {
  display: block;
  color: var(--txt-color-Htag);
  font-size: var(--font-large-size);
  font-weight: var(--font-weight-semibold);
}

.tab-accordions .accordion-item .accordion-button span {
  color: var(--txt-color-Htag);
  font-size: var(--font-large-size);
  vertical-align: middle;
  font-weight: var(--font-weight-semibold);
}

.tab-accordions .accordion-button:focus {
  box-shadow: none;
}

.tab-accordions .accordion-button::after {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-left: auto;
  content: "";
  display: none;
}

.tab-accordions .accordion-body {
  padding: 10px 0 0 16px;
}

.tab-accordions .accordion-body p {
  /* border-top: 1px solid #a5a5a5; */
  padding: 15px 0 10px;
  margin: 0;
  color: var(--txt-color-Htag);
  background: url(../images/file-icon.svg) no-repeat left;
  padding-left: 32px;
  font-size: var(--font-medium-size);
  background-size: 1.5%;
}

.faq-accordions .accordion-body {
  padding: 10px 0 0 28px;
}

/* .tab-accordions .accordion-body p:last-child {
  border-bottom: 1px solid #a5a5a5;
} */
.common-crs-details h5 {
  margin: 0;
  font-size: var(--font-x-size);
}

.common-img-2 {
  /* width: 70%; */
  padding: 0px 24px;
}

.common-img-2 img {
  width: 200px;
}

.b-bottom {
  border-bottom: 1px solid #cfcfcf;
}

.common-crs-details .des-text {
  font-size: var(--font-large-size);
  line-height: 36px;
  color: var(--des-lgt-color);
}

h5.border-bottom {
  font-size: var(--font-xl-size);
  padding: 15px;
  margin-bottom: 24px;
}

.common-crs-details .b-bottom:last-child {
  border-bottom: none;
}

.tab-accordions.faq-accordions .accordion-body p {
  border: none;
  padding: 15px 0px;
  background: none;
}

.btn .btn-icon {
  margin-right: 5px;
  color: #15a8f3;
}

.btn:hover .btn-icon {
  margin-right: 5px;
  color: #182457;
}

/* .tab-accordions.faq-accordions .accordion-item .accordion-button:before {
  top: 55px;
} */
.tab-accordions.faq-accordions
  .accordion-item
  .accordion-button.collapsed::before {
  top: 8px;
}

.faq-thumb {
  position: absolute;
  right: 0;
  top: 0px;
}

.faq-thumb .btn-none {
  color: #3d4a42;
}

.faq-thumb .btn-none:hover {
  color: var(--btn-sky-blue);
}

/**************************/
/*     Placement page     */
/**************************/
.placement-page .sub-banner {
  height: 230px;
}

.placement-details {
  background: var(--bg-white-color);
}

.trust-des .des-txt {
  color: var(--des-lgt-color);
  padding: 0px;
  margin: 0px;
  text-align: justify;
}

/* .recent-placed-students-details {
  padding-top: 40px;
} */

.recent-placed-students h2 {
  text-align: center;
  padding: 40px 20px 30px 20px;
}

.got-placed {
  padding-bottom: 40px;
}

.got-placed .text-h5 {
  color: var(--txt-blk-color);
  margin-bottom: 13px;
}

.text-h6,
.got-placed .text-h6 {
  color: var(--des-lgt-color);
  margin-bottom: 20px;
  position: relative;
  font-size: var(--font-large-size);
  padding: 0;
}

.text-h6::after,
.got-placed .text-h6::after {
  content: "";
  border-bottom: 1px solid #a4a4a3;
  display: block;
  width: 30%;
  position: absolute;
  bottom: -10px;
}

.text-h6 {
  padding-top: 10px;
  padding-bottom: 20px;
}

.text-h6::after {
  width: 70px;
}

.got-placed p {
  color: var(--des-lgt-color);
  font-size: var(--font-medium-size);
}

.recent-placed-students .h1-large {
  text-align: center;
}

.recent-placed-students-details .placement-student-img {
  width: 35%;
  position: relative;
}

.recent-placed-students-details .placement-student-img img {
  border-radius: 100%;
  border: 2px solid #979696;
}

.play {
  background: var(--dark-blue-bg);
  border-radius: var(--box-border-radius);
  color: var(--txt-wht-color);
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -12px;
  padding: 10px 5px;
  right: 16px;
  border-radius: 40px;
}

.play span {
  line-height: 1;
  padding-left: 7px;
}

span.play-button .play_icon {
  width: 30px;
  border: 0;
}

span.play-button {
  font-size: var(--font-xxl-size);
  line-height: 10px;
  padding-left: 10px;
}

.feature-block {
  padding-bottom: 40px;
}

.feature-block h2 {
  padding: 0px 20px 30px;
}

.feature-block .h1-large {
  padding-bottom: 45px;
}

.feature-block-divider {
  padding-top: 20px;
}

.recent-placed-students .service-card {
  background: var(--bg-white-color);
  box-shadow: 0px 3px 20px #00000014;
  border-radius: var(--box-border-radius);
  border: none;
}

.service-card-body img {
  width: 40px;
  height: 40px;
}

/*******************/
/*     Pagination     */
/*******************/
.page-link.active,
.active > .page-link,
.page-link {
  border-radius: 100%;
}

.page-link {
  margin-right: 23px;
  padding: 8px 16px;
  background: var(--bg-white-color);
  border: 1px solid #e5e5e5;
  color: var(--txt-color-Htag);
  font-weight: var(--font-weight-semibold);
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 100%;
}

.page-link.active,
.active > .page-link {
  border: 1px solid var(--btn-sky-blue);
  background: var(--bg-color);
  color: #213a8e;
  font-weight: var(--font-weight-semibold);
}

/*************************/
/*     Workshop page       <-------------------------------->*/
/*************************/
.workshop-tabs {
  background: var(--bg-white-color);
}

.workshop-tabs .content-tabs {
  padding-top: 20px;
}

.workshop-tabs .tabs-nav {
  position: relative;
  top: -50px;
}

.workshop-tabs .content-tabs .col-lg-8,
.workshop-tabs .content-tabs .col-lg-4 {
  padding: 0;
}

.event-card {
  display: flex;
  margin-bottom: 27px;

  padding: 15px;
  border-radius: var(--btn-border-radius);
  box-shadow: 0px 3px 20px #c2c20f14;
}

.event-card:last-child {
  margin-bottom: 60px;
}

.left_side_event img {
  width: 320px;
  height: 220px;
  border-radius: var(--btn-border-radius);
  object-fit: cover;
}

.left_side_event {
  position: relative;
  padding-right: 40px;
}

.left_side_event .btn {
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: var(--btn-border-radius);
}

.left_side_event .btn.upcoming {
  background: var(--btn-sky-blue);
}

.left_side_event .btn.upcoming:hover {
  background: var(--btn-dr-blue);
  border: 1px solid var(--btn-dr-blue);
}

.left_side_event .btn.completed {
  background: var(--btn-light-green);
}

.left_side_event .btn.completed:hover {
  background: var(--btn-dr-blue);
  border: 1px solid var(--btn-dr-blue);
}

.find_workshop {
  box-shadow: 0px 3px 20px #00000014;
  border-radius: var(--btn-border-radius);
  padding: 20px;
}

.right_side_event span {
  font-size: var(--font-large-size);
  margin-bottom: 40px;
  display: inline-flex;
  color: var(--des-lgt-color);
}

.right_side_event h3 {
  color: var(--txt-blk-color);
  font-weight: var(--font-weight-bold);
  margin-bottom: 20px;
  font-size: var(--font-xl-size);
}

.date_picker {
  position: relative;
  padding-bottom: 15px;
}

.date_time {
  color: var(--des-lgt-color);
  font-size: var(--font-large-size);
  position: relative;
  top: 2px;
}

.fw-medium.timer svg {
  position: relative;
  top: 1px;
}

.event-card .right_side_event .btn {
  padding: 10px 25px;
  line-height: 1;
}

.react-datepicker-wrapper input {
  width: 100%;
  height: 36px;
  font-size: var(--font-medium-size);
  border-radius: var(--btn-border-radius);
  border: 0;
  color: var(--des-lgt-color);
  padding: 7px 17px;
  background: var(--bg-white-color);
  font-weight: var(--font-weight-regular);
  outline: none;
  box-shadow: none;
  border: 1px solid var(--border-color);
}

.input_search .search-input-box {
  background: transparent;
}

.date_picker span {
  cursor: pointer;
  position: absolute;
  right: 143px;
  top: 0px;
  color: var(--btn-sky-blue);
  padding: 5px 15px;
}

.form-container.keyword_select {
  padding: 0;
  border-radius: var(--btn-border-radius);
  padding-bottom: 15px;
}

.form-container.keyword_select .form-select {
  border: 1px solid var(--border-color);
  box-shadow: none;
  outline: none;
  color: var(--des-lgt-color);
  font-size: var(--font-medium-size);
  height: 36px;
}

.input_search,
.search-input-box {
  position: relative;
  width: 100%;
  outline: none;
}

.input_search span {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 2px;
  color: var(--btn-sky-blue);
  padding: 5px 15px;
}

/**************************/
/*     Internship page  v2   */
/**************************/

.internship-page {
  background: #fcfbfb;
}

.apply-float {
  font-size: 16px;

  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.apply-float:hover {
  background-color: #22a0f4;
}

.mission-section {
  padding-block: 2rem;
  font-size: 1.5rem;
  display: flex;
  align-items: end;
  padding-top: 40px;
}

.mission-text-and-points-section {
  display: flex;
  flex-direction: column;
}

.mission-text-section {
  padding-block: 20px;
  padding-right: 20px;
}

.mission-text-section h1 {
  font-size: 2.5rem;
  margin-left: 10px;
  color: #000;
  font-family: var(--bnr-font);
}

.mission-text-section p {
  font-size: 15px;
  font-weight: 5z00;
  color: rgb(100, 99, 99);
  font-family: var(--bnr-font);
}

.mission-points-section {
  padding: 30px 40px;
  line-height: 2.4;
  background: #23408e;
  border-radius: 20px 0px 0px 20px;
}

.mission-points-section li::marker {
  content: "🔵  ";
  font-size: 15px;
}
.mission-points-section li {
  color: white;
  font-size: 18px;
}

.mission-image-section img {
  background-size: cover;
  aspect-ratio: 16/9;
  height: 400px;
  width: 470px;
}

.overview-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 2rem;
}

.overview-section .text-section {
  text-align: center;
  margin-bottom: 20px;
}

.overview-explore-section {
  display: flex;
  max-width: 100%;
  gap: 30px;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.overview-section .text-section h1 {
  font-size: 2.5rem;
  color: black;
  text-transform: capitalize;
  font-family: var(--bnr-font);
  text-align: center;
}

.overview-section .text-section p {
  color: rgb(113, 112, 112);
  font-family: var(--bnr-font);
  max-width: 60%;
  text-align: center;
}

.overview-section .points-section {
  display: flex;
  gap: 10px;
}

.overview-section .point-cards {
  padding-block: 20px;
  padding-inline: 15px;
  background: #000;
  border-radius: 5px;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.overview-section .card1 {
  background: #cdecfb;
}
.overview-section .card2 {
  background: #b3e0f7;
}
.overview-section .card3 {
  background: #93d8fb;
}
.overview-section .card4 {
  background: #6bcbfb;
}

.points-section .card-icon img {
  display: grid;
  place-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: white;
}

.phases-section {
  padding-top: 20px;
  border-radius: 10px;
}

.phases-section h1 {
  text-align: center;
  background: #e7f8ff;
  border-radius: 5px;
  padding: 10px 0px;
  color: #000;
  font-family: var(--bnr-font);
}

.phases-section p {
  text-align: center;
  color: rgb(132, 131, 131);
  margin-top: 30px;
}

.phases-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
}

.phases-box {
  background-color: white;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}

.phase-shadow-box {
  box-shadow: 0px 0px 20px 5px rgba(128, 127, 127, 0.356);
  border-radius: 10px 0px 0px 0px;
}

.phases-box-image1 {
  background-image: url("../images/internship_learning.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0px 10px 0px 0px;
  height: 350px;
}

.phases-box-image2 {
  background-image: url("../images/internship_development.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 350px;
}

.phases-box h4 {
  line-height: 1.3;
  font-size: 21px;
  color: #000;
  font-family: var(--bnr-font);
}

.phases-box-text {
  padding: 20px 30px;
  gap: 30px;
}

.phases-box-text p {
  font-size: 18px;
  line-height: 1.3;
  text-align: start;
  text-wrap: balance;
}

.phases-footer {
  padding: 30px 100px;
  color: white;
  font-weight: 700;
  text-align: center;
  background: #23408e;
  border-radius: 0px 0px 10px 10px;
  font-family: var(--bnr-font);
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.internship-cards {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
}

.section-title {
  color: #000;
  text-align: center;
  font-size: 32px;
  font-family: var(--bnr-font);
  margin-bottom: 30px;
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  perspective: 1000px;
}

.internship-card {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 400px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease;
  transform-style: preserve-3d;
}

.internship-card:hover,
.internship-card.active {
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-block: 1rem;
  color: black;
  transition: transform 0.3s ease;
  text-align: start;
  width: 100%;
}

.internship-card:hover .card-title,
.internship-card.active .card-title {
  transform: translateZ(20px);
}

.card-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
  text-align: start;
  transition: transform 0.3s ease;
}

.internship-card:hover .card-description,
.internship-card.active .card-description {
  transform: translateZ(20px);
}

.card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #23408e;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 100;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px;
  margin-top: auto;
  margin-right: auto;
}

.card-button:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background: #22a0f4;
  color: white;
}

.card-button svg {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.stories-section-wrapper {
  background: #f3f4f8;
}

.stories-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
}

.stories-section h2 {
  text-align: center;
  color: black;
  padding: 20px 20px;
}

.stories-grid {
  padding-block: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.stories-card {
  padding: 10px 20px;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.stories-image-section {
  display: flex;
  align-items: center;
  gap: 20px;
}

.stories-image-section h5 {
  color: black;
  font-size: 16px;
}

.stories-card p {
  text-align: center;
  color: rgb(113, 113, 113);
}

.storie-image {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  margin-bottom: 10px;
  object-fit: cover;
}

.form-section {
  background-image: url("../images/form-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  padding: 50px 50px;
  display: flex;
  gap: 30px;
  justify-content: center;
}

.form-aligner {
  width: 0%;
  display: flex;
}
.form-box {
  background: white;
  max-width: 700px;
  border-radius: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.462);
  padding-top: 20px;
}

.form-box h2 {
  color: black;
  text-align: center;
  font-family: var(--bnr-font);
}

.form-section h1 {
  color: white;
  font-size: 40px;
  width: 40%;
  margin-top: 30px;
  font-family: var(--bnr-font);
}

.certificate-section {
  background-image: url("../images/certificate-main1.png");
  background-position-x: left;
  background-size: cover;
  padding: 3rem 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.certificate-text-section {
  width: 40%;
}

.certificate-text-section h1 {
  color: black;
  margin-bottom: 20px;
  font-family: var(--bnr-font);
}

.certificate-section img {
  width: 30%;
}

.certificate-text-section h5 {
  color: rgb(109, 108, 108);
  font-weight: 300;
}

@media (max-width: 1200px) {
  .overview-section .points-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .mission-section {
    align-items: end;
  }

  .mission-points-section {
    line-height: 2;
  }

  .certificate-section {
    flex-direction: column;
    gap: 30px;
  }

  .certificate-text-section {
    width: 80%;
  }
  .date_picker span {
    right: 130px;
  }
}
/* ******************************************* */
/* Home-page video play pause button media quaries  */
/* ******************************************* */

@media screen and (min-width: 992px) and (max-width: 1075px) {
  .video-btn button {
    width: 6.3rem;
    height: 56px;
    right: 7px;
    top: 42.3%;
  }
  .tabs {
    padding: 10px 7px 10px 10px;
    border: none;
    background: none;
    font-size: 19px;
    color: var(--des-lgt-color);
    margin-right: 0px;
  }
  .active-tab {
    color: var(--txt-dr-color);
    border-bottom: 3px solid var(--txt-dr-color);
    font-weight: var(--font-weight-semibold);
  }
  .tabs-nav {
    width: 97%;
    padding: 10px 0px 10px 0px;
  }

  .browse-blc a {
    float: none;
    margin-left: 65px;
  }
  .img-fluid {
    width: 98%;
  }
  .content-tabs {
    padding-right: 20px;
  }
  .upcoming-batch table td.c-td .btn.float-end {
    position: static;
    top: auto;
    left: auto;
  }
  .upcoming-schedule-training {
    margin-right: 10px;
  }

  .tab-item {
    height: 320px;
  }
  .service-card {
    padding: 25px 25px;
  }
  .justify-content-center {
    margin-right: 20px;
  }
  .rightslide-carousel-reviews-arrow-image {
    right: 25px;
  }
  .col-lg-12 {
    width: 99%;
  }
  .col-lg-3 {
    margin-right: -2px;
  }
  /* #search-span{
    top:20px;
} */
  /* .diff-style {
    top: 22px;
  } */
  .date_picker span {
    right: 72px;
  }
}

@media (max-width: 991px) {
  .phases-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
  }

  .mission-section {
    flex-direction: column;
    place-items: center;
    gap: 20px;
  }

  .mission-points-section {
    border-radius: 20px;
  }

  .mission-image-section img {
    width: 400px;
    height: 301px;
    align-self: center;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .form-section {
    flex-direction: column;
  }
  .form-section h1 {
    margin: auto;
    width: 100%;
  }
  .certificate-section img {
    width: 80%;
  }
  .overview-explore-section {
    flex-direction: column;
  }

  .overview-section .text-section h1 {
    text-align: center;
  }

  .overview-section .text-section p {
    max-width: 90%;
    text-align: center;
  }
}
@media (max-width: 600px) {
  .overview-section .points-section {
    grid-template-columns: repeat(1, 1fr);
  }
  .stories-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .phases-box {
    height: auto;
    border: 1px solid rgb(230, 227, 227);
    border-radius: 10px;
  }
  .phase-shadow-box {
    box-shadow: none;
    border-radius: 10px 0px 0px 0px;
  }

  .mission-image-section img {
    width: 100%;
    align-self: center;
    margin: auto;
  }

  .phases-box-image1 {
    background-size: contain;
  }
  .phases-box-image2 {
    background-size: contain;
    order: 4;
  }

  .phases-footer {
    margin-top: 10px;
  }

  .form-box {
    width: 100%;
    padding-block: 10px;
  }
  .form-box h2 {
    margin-bottom: 20px;
  }
}

/* all internship pages */

:root {
  --dark-blue: #ffffff;
  --light-blue: #3498db;
  --accent-blue: #64b5f6;
  --text-light: #000000;
  --text-dark: #000000;
}

.python-internship-page {
  font-family: "Arial", sans-serif;
  background-color: white;
  color: var(--text-light);
  line-height: 1.6;
  padding: 20px 0px;
}

.genai-internship-page {
  font-family: "Arial", sans-serif;
  background-color: white;
  color: var(--text-light);
  line-height: 1.6;
  padding: 20px 0px;
}

.internship-header {
  padding: 60px 20px;
  text-align: center;
}

.internship-heading {
  padding: 20px 20px;
  text-align: center;
  font-size: 2.5rem;
  background: rgba(27, 151, 252, 0.233);
  width: fit-content;
  margin: auto;
  border-radius: 10px;
}

.internship-breadcrumb {
  position: absolute;
  left: 20%;
}

.header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.header p {
  font-size: 1.2rem;
  opacity: 0.9;
}

.nav-menu {
  display: flex;
  justify-content: center;
  gap: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 0;
}

.intern-nav-item {
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  background: #1f3b84;
  border: none;
  border-radius: 10px;
}

.intern-nav-item:hover,
.intern-nav-item.active {
  color: white;
  background: #34a8f3;
}

.main-content {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.highlights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.highlight-item {
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

.highlight-number {
  font-size: 2.5rem;
  color: var(--accent-blue);
  display: block;
}

.highlight-text {
  font-size: 1rem;
}

.overview-description {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 20px 20px;
  max-width: 900px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: #e1f0ff;
}

.overview-con h2 {
  margin-top: 2rem;
  background-color: rgba(208, 230, 245, 0.3);
  width: max-content;
  border-radius: 10px;
  padding: 20px 40px;
}

.additional-information {
  background-color: #e1f7ff;
  padding: 20px;
  border-radius: 10px;
}

.timeline {
  position: relative;
  max-width: 800px;
  margin: 40px auto 0;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: var(--light-blue);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.timeline-item {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

.timeline-item:nth-child(odd) {
  left: 0;
}

.timeline-item:nth-child(even) {
  left: 50%;
}

.timeline-content {
  padding: 20px 30px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  border-radius: 6px;
}

.apply-section {
  max-width: 900px;
  border-radius: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.462);
  padding: 20px 0px;
}

.apply-section h2 {
  text-align: center;
}

.footer {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 40px 20px;
}

.testimonial {
  max-width: 800px;
  margin: 20px auto 40px;
  text-align: end;
  text-wrap: pretty;
}
.testimonial footer {
  height: 100px;
}

.testimonial blockquote {
  font-size: 1.2rem;
  font-style: italic;
  text-align: center;
}

.apply-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  gap: 100px;
}

.stats {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 80px;
}

.stat-item {
  text-align: center;
}

.stat-number {
  font-size: 2rem;
  color: var(--accent-blue);
  display: block;
}

.stat-label {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .apply-main {
    flex-direction: column;
  }

  .apply-section {
    width: 400px;
    padding: 20px 40px;
  }
  .apply-section h2 {
    margin-bottom: 20px;
  }

  .timeline::after {
    left: 31px;
  }

  .timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  .timeline-item:nth-child(even) {
    left: 0;
  }
}

@media (max-width: 768px) {
  .apply-section {
    max-width: 400px;
  }
}

.phases-con {
  background-color: #ffffff;
  padding: 50px 30px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  max-width: 1200px;
  margin: auto;
}

.phase-title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.phase-content {
  font-size: 18px;
  line-height: 1.8;
  color: #555;
}

.phase-description {
  margin-bottom: 25px;
  text-align: center;
  font-size: 20px;
  color: #444;
}
.phase-details {
  margin-bottom: 25px;
  padding: 20px;
  background-color: #f7f7f7;
  border-left: 5px solid #007bff;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.phase-details:hover {
  background-color: #eef5ff;
}

.phase-details strong {
  font-size: 20px;
  color: #007bff;
  display: block;
  margin-bottom: 10px;
}

.phase-conclusion {
  font-size: 18px;
  color: #333;
  text-align: center;
  margin-top: 30px;
}

/* internship form */
.internship-form-container {
  min-width: auto;
  margin: 0 auto;
  padding: 2rem;
  background-color: transparent;
  border-radius: 8px;
}

.form-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333333;
}

.internship-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 1200px) {
  .internship-form-container {
    min-width: auto;
  }
}

@media (max-width: 991px) {
}

@media (max-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr;
    gap: 0rem;
  }
  .file-input-wrapper {
    flex-direction: column;
    align-items: start;
  }
  .selected-file {
    padding: 2px 2px;
    border: 1px solid silver;
    border-radius: 10px;
    text-wrap: wrap;
    font-size: 60%;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: #4a5568;
}

.form-group input,
textarea,
Select {
  width: 100%;
  padding: 10px 20px;
  font-size: 15px;
  border: none;
  border-radius: 5px;
  background-color: #f3f4f8;
  transition: border-color 0.2s ease-in-out;
}

.form-group textarea::placeholder,
input::placeholder {
  color: rgb(166, 164, 164);
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

select {
  appearance: none;
  padding-right: 2rem;
}

.file-input-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  cursor: pointer;
  flex-wrap: wrap;
}

.selected-file {
  padding: 8px 30px;
  border: 1px solid silver;
  border-radius: 10px;
  text-wrap: wrap;
}

.file-input-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.file-input-text {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #ffffff;
  background-color: #4299e1;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.file-input-text:hover {
  background-color: #3182ce;
}

/* .internship-submit-button {
  max-width: max-content;
  padding: 10px 30px;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #4299e1;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-left: auto;
} */

.internship-submit-button {
  width: fit-content;
  margin-left: auto;
  padding-inline: 20px;
}

.internship-submit-button:hover:not(:disabled) {
  background-color: #1f3b84;
}

.internship-submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/*******************/
/*    Whats app     */
/*******************/
._1yCVn {
  bottom: 100px !important;
  z-index: 9999;
}

._2qp0Z {
  z-index: 99;
}

/*******************/
/*     Workshop detail page     */
/*******************/
.workshop-detail-view h3 {
  color: var(--txt-blk-color);
  font-size: var(--font-xl-size);
  font-weight: var(--font-weight-regular);
}

.workshop-detail-sub-banner {
  padding-top: 80px;
  background: #fcfbfb;
}

.workshop-detail-banner {
  padding-bottom: 20px;
}

.workshop-detail-banner img {
  width: 100%;
  height: 490px;
  border-radius: 10px;
}
.workshop-detail-banner img:last-child {
  object-fit: cover;
}

.workshop-detail-banner .date-time-display {
  display: flex;
  justify-content: flex-end;
}

.workshop-detail-banner .fw-medium {
  display: flex;
  align-items: center;
  color: var(--btn-sky-blue);
}

.ws-text {
  color: var(--des-lgt-color);
  font-size: var(--font-large-size);

  line-height: 1.8rem;
}

.workshop-cost-detail {
  background: var(--bg-white-color);
  box-shadow: 0px 0px 20px #1f305d0d;
  border-radius: var(--btn-border-radius);
  padding: 15px;
  margin-bottom: 20px;
}

.workshop-cost-detail h3,
.map-location h3 {
  font-weight: var(--font-weight-semibold);
  color: #2d2d2d;
  padding-bottom: 15px;
  margin: 0;
}

.free-cost {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.free-cost .cost {
  color: #2d2d2d;
  font-weight: var(--font-weight-semibold);
}

.free-cost .free {
  font-weight: var(--font-weight-bold);
  color: var(--dark-red-color);
}

.workshop-cost-detail input {
  border: 0;
  border-bottom: 1px solid #a4a4a3;
  width: 100%;
  outline: none;
  padding: 0px;
  color: var(--txt-blk-color);
}

button.success {
  color: var(--txt-wht-color);
  background-color: var(--btn-light-green);
  padding: 8px 30px 8px;
  font-size: var(--font-medium-size);
  border: 0;
  border-radius: var(--btn-border-radius);
  margin: 15px auto 0 auto;
  display: flex;
}

.map-location {
  background: var(--bg-white-color);
  box-shadow: 0px 0px 20px #1f305d0d;
  border-radius: var(--btn-border-radius);
  padding: 15px;
}

.map iframe {
  width: 100%;
  height: 266px;
}

.workshop-detail-team-member {
  padding: 40px 0px 100px;
}

/*******************/
/* Social Activity Page */
/*******************/
.workshop-detail-sub-banner.social_detail_view {
  padding-top: 75px;
}

.social-activity-section {
  background: var(--bg-white-color);
}

.social-activity-section h3 {
  font-weight: var(--font-weight-bold);
  color: var(--txt-color-Htag);
  padding: 40px 0px 20px;
  margin: 0;
}

.social-activity-section p {
  margin-bottom: 40px;
}

.social-activity-section .social-a {
  background: var(--bg-white-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 20px #1f305d12;
  border: 2px solid var(--btn-sky-blue);
  border-radius: var(--box-border-radius);
  text-decoration: none;
  display: block;
}

.social-activity-section .social-a img {
  border-radius: 10px 10px 0px 0px;
}

.social-service {
  padding-bottom: 100px;
}

.social-service p {
  margin-bottom: 50px;
}

.social_detail_view .social-service {
  padding-bottom: 40px;
}

.social_detail_view .social-service p {
  margin-bottom: 20px;
}

.social_detail_view .col-lg-5 img {
  object-fit: cover;
  height: 350px;
  border-radius: 10px;
}
.col-lg-5 {
  display: grid;
  place-items: center;
}

/*******************/
/*     About us     */
/*******************/
.sub-banner-white {
  background: var(--bg-white-color);
  text-align: center;
  padding: 30px 0;
  box-shadow: 0px 0px 20px #1f305d12;
  border-radius: var(--btn-border-radius);
}

.common-block h2 {
  padding-bottom: 20px;
}

.common-block {
  padding: 40px 0;
}

.common-block .h1-large {
  padding-bottom: 0px;
}

.common-block .des-txt {
  padding-top: 0;
  padding-bottom: 20px;
  line-height: 1.5;
}

.common-block ul {
  padding: 0px 0px 0px;
}

.common-block-2 {
  position: relative;
  padding: 0px;
  background: var(--bg-color);
}

/* .bg-holder {
  background: url(../images/admission-img.png) no-repeat;
} */
/* .bg-holder {
  position: absolute;
  width: 50%;
  background-size: contain;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0;
  height: 70%;
} */
.adminssion-year {
  padding: 0px 100px 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.adminssion-year .btn {
  display: block;
  width: 125px;
  line-height: 1;
  padding: 10px;
}

.mission-vision-block {
  background-color: rgba(33, 58, 142, 1);
  width: 100%;
  font-size: var(--font-xl-size);
  z-index: 9;
  position: relative;
  padding: 40px 0px 40px 0px;
}

.mission-vision-block::after {
  content: "";
  background: url(../images/running_man.png) no-repeat;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background-size: contain;
}

.mission-vision-block h3 {
  color: var(--txt-wht-color);
  font-size: var(--font-xl-size);
  margin-bottom: 20px;
}

.mission-vision-block p {
  color: var(--txt-wht-color);
  font-size: var(--font-large-size);
  width: 90%;
}

.vision_container {
  margin-bottom: 20px;
}

.vision_box {
  display: flex;
}

.vision_text {
  background: #22adf3;
  margin-right: 10px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--btn-border-radius);
  font-size: var(--font-large-size);
  color: var(--txt-wht-color);
  text-align: center;
  width: 213px;
  height: 80px;
}

.expert_instructor {
  background: var(--bg-white-color);
  padding: 40px 0px 40px 0px;
}

.expert_instructor h3 {
  color: var(--txt-color-Htag);
  font-size: var(--font-xxxl-size);
  margin-bottom: 50px;
}

.profile_details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.profile_content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_content img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.profile_content .btn {
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: auto;
  right: auto;
}

.tr_pr_detail {
  padding: 0px 20px;
}

.tr_name {
  color: var(--txt-blk-color);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-x-size);
}

.tr_role {
  color: var(--des-lgt-color);
  font-size: var(--font-large-size);
  padding-bottom: 30px;
  border-bottom: 1px solid #c5c5c5;
  width: 90px;
}

.team_profile .tr_role {
  border: none;
  padding: 0;
  width: auto;
}

.expert_instructor p {
  color: var(--des-lgt-color);
  font-size: var(--font-large-size);
}

.team_member_info {
  padding: 40px 0px 40px 0px;
}

.team_member_info h3 {
  margin-bottom: 15px;
}

.team_member_detail {
  display: inline;
  justify-content: space-between;
  align-items: center;
  width: auto;
}

.team_profile {
  display: "inline-block";
  margin: "20px";
  align-items: center;
}

.team_profile img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}

.team_profile .tr_pr_detail {
  padding: 20px;
  text-align: center;
}

/*******************/
/*     Contact     */
/*******************/

.contact-form {
  padding: 40px;
  background: url(../images/contact-bg.png) no-repeat;
  background-size: cover;
  scroll-margin-top: 50px;
}

.rl_course {
  padding-bottom: 30px;
}

.form-container {
  background: var(--bg-white-color);
  padding: 20px;
  border-radius: var(--box-border-radius);
  text-align: center;
}

.form-container .form-control {
  box-shadow: none;
  outline: none;
  border-color: var(--border-color);
  position: relative;
}

.form-container .form-select {
  color: #212529;
}

.form-container .form-control:focus,
.form-container .form-control:focus {
  background: #fff !important;
}

.invalid-feedback {
  text-align: left;
}

.form-container h2 {
  padding-bottom: 15px;
  margin: 0px;
  font-family: var(--bnr-font);
  color: var(--txt-color-Htag);
  font-weight: var(--font-weight-semibold);
}

.form-container h6 {
  font-size: var(--font-l-size);
  padding-bottom: 7px;
  margin: 0px;
  color: #2d2d2d;
  font-weight: var(--font-weight-semibold);
}

.contact-form .h1-large {
  line-height: var(--line-height-h1);
  color: var(--txt-wht-color);
  margin: 26% 0 0 0;
  width: 50%;
  text-align: center;
  font-weight: normal;
}

.form-container .react-tel-input .form-control {
  width: 100%;
  margin-bottom: 15px !important;
  border-radius: 4px;
  border: 1px solid #ced4da;
  height: 38px;
  padding: 0px 38px;
}

/* .form-container .form-control,
.form-container .react-tel-input .form-control {
  background-color: transparent;
  border: 1px solid var(--border-color);
  font-size: var(--font-medium-size);
  line-height: 36px;
  color: var(--txt-blk-color);
  outline: none;
  box-shadow: none;
}

.form-container .react-tel-input .form-control {
  padding: 5px 15px 5px 10px;
  margin-left: 24px;
  width: 94%;
}*/
.form-container .react-tel-input .flag-dropdown {
  border: none;
  background-color: transparent !important;
}

.form-container .react-tel-input .selected-flag {
  border: none;
  background-color: transparent !important;
}

.form-container .react-tel-input .flag-dropdown.open,
l.form-container .react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: transparent !important;
}

.react-tel-input .country-list .country,
.react-tel-input .country-list .country.highlight {
  text-align: left;
}

.react-tel-input .form-control {
  padding-right: 0;
  outline: none;
  box-shadow: none;
}

.react-tel-input .country-list {
  width: 22vw;
  margin: 10px auto 10px -10px;
}

.form-container .react-tel-input .country-list .search-box,
.login .react-tel-input .country-list .search-box {
  border: 1px solid #cacaca;
  border-radius: 3px;
  font-size: 15px;
  line-height: 15px;
  padding: 3px 8px 5px;
  outline: none;
  height: 38px;
  width: 97%;
  margin: 0px;
}

/* .login .react-tel-input {
  margin-bottom: 20px;
} */

/* .login .react-tel-input {
  margin-bottom: 20px;
} */

.form-container .react-tel-input .country-list .search {
  padding: 10px 0 6px 5px;
}

.react-tel-input .country-list .search-emoji {
  display: none;
}

.form-divider {
  padding-bottom: 15px;
}

.form-container .btn-sky-blue {
  width: 100%;
  border: 0;
  font-size: var(--font-medium-size);
  line-height: 30px;
  font-weight: var(--font-weight-semibold);
}

.comment_box .form-container .btn-sky-blue {
  width: 100px;
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  position: relative;
  top: -24px;
  justify-content: center;
}

.comment_box .SuccessFormMessage {
  width: auto;
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  position: relative;
  top: -24px;
  justify-content: center;
  background: transparent;
  color: #3ed1a4;
}

/******************/
/*     Footer     */
/******************/
.footer-top {
  padding: 30px 20px 20px;
  background-color: var(--dark-blue-bg);
}

.footer-bottom {
  padding: 10px;
  height: 45px;
  display: flex;
  align-items: center;
}

#footer h4,
#footer h3 {
  font-size: var(--font-large-size);
  color: var(--txt-wht-color);
  padding-bottom: 10px;
  border-bottom: 1px solid var(--btn-sky-blue);
  line-height: 1;
  margin-bottom: 5px;
  font-weight: var(--font-weight-semibold);
}

#footer ul {
  padding: 0;
}

#footer ul li {
  list-style: none;
  padding-bottom: 5px;
}

#footer ul li:last-child {
  padding: 0;
}

#footer ul li a {
  color: #add7e8;
  text-decoration: none;
  font-size: var(--font-medium-size);
  text-transform: capitalize;
}

.footer-contact {
  width: 33%;
}

.footer-contact p {
  color: #add7e8;
  font-size: var(--font-medium-size);
  line-height: 30px;
  margin: 0px;
}

/* .footer-links {
  width: 20%;
} */
.footer-links,
.footer-contact {
  padding: 0;
}

.footer-contact span img {
  vertical-align: baseline;
}

.footer-contact .address {
  display: inline-flex;
  line-height: 18px;
}

/*********************/
/*     Copyright     */
/*********************/
.footer-bottom .copyright {
  padding-bottom: 0;
  text-align: left;
  color: var(--txt-dr-color);
  position: relative;
  font-size: var(--font-small-size);
}

.copyright a {
  font-size: var(--font-xxxl-size);
  color: var(--txt-dr-color);
  padding-left: 15px;
}

.social-links {
  position: absolute;
  right: 0;
  top: -15px;
}

.social-links a svg {
  width: 26px;
  display: inline-block;
  height: 26px;
}

.tab-content-image {
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-gray-color);
  border-radius: var(--box-border-radius);
  width: 100%;
}

.tab-content-image {
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-gray-color);
  border-radius: var(--box-border-radius);
  width: 100%;
}

.tab-content-image {
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-gray-color);
  border-radius: var(--box-border-radius);
  width: 100%;
}

.tab-content-image img {
  width: 100%;
  min-height: 160px;
  min-height: 160px;
  height: 160px;
  min-height: 160px;
  border-radius: var(--box-border-radius);
  object-fit: cover;
}

/* .content.active-content .col-lg-3.pe-0:nth-child(4n) {
  padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
} */

.box_container {
  display: flex;
  align-items: center;
  color: var(--txt-wht-color);
  text-align: left;
  font-size: var(--font-xl-size);
}

.privacy_policy h1 {
  padding-top: 20px;
}

.privacy_policy .common-block {
  padding: 20px 0px;
}

.privacy_block {
  padding: 40px;
}

.protect_data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.data_protect ul li {
  padding-bottom: 5px;
  font-weight: 600;
  color: #000;
}

.data_protect p {
  padding-left: 25px;
}

.box {
  margin: 20px 30px;
  text-align: center;
}

.box .box_heading {
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.box .box_heading svg {
  color: #ffc535;
}

.box .box_heading span {
  font-size: var(--font-large-size);
  width: 100%;
}

.box .box_body {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-medium-size);
}

.under_construction {
  display: flex;
  justify-content: center;
  padding: 40px;
  background: #fff;
  flex-direction: column;
  align-items: center;
}

.under_construction h2 {
  width: 100%;
  text-align: center;
}

/*************************/
/*   Syllabus modal css  */
/*************************/
.modal.syllabus_modal .modal-content,
.modal.syllabus_modal .modal-dialog {
  max-width: 700px;
}

.modal.syllabus_modal .modal-content {
  background-color: #fff;
  height: 300px;
  border-radius: var(--box-border-radius);
  border: none;
}

.modal.syllabus_modal .modal-body .syllabus_content {
  background: var(--dark-blue-bg);
  border-radius: 12px 0px 0px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  height: 300px;
  text-align: center;
}

.syllabus_content p {
  color: var(--txt-wht-color);
  font-size: var(--font-medium-size);
  padding: 15px 10px 0px;
  margin: 0;
}

.syllabus_content img {
  height: 200px;
}

/* Syllabus login*/
.syllabus_login {
  position: relative;
}

.modal-body .close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1.5px solid #a4a4a3;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9;
}

.modal-body .close-modal span {
  font-size: 25px;
  color: #a4a4a3;
  line-height: 1;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.syllabus_login .heading {
  padding: 40px 20px 20px;
  text-align: center;
}

.syllabus_login h5 {
  color: #2d2d2d;
  font-size: var(--font-l-size);
}

.syllabus_login h2 {
  color: #242424;
  font-size: var(--font-x-size);
}

.syllabus_login .login {
  padding: 0px 20px 40px;
}

.syllabus_login .login .form-control {
  font-size: var(--font-medium-size);
  box-shadow: none;
  outline: none;

  border-radius: var(--btn-border-radius);
  width: 100%;
  height: 39px;
}

.syllabus_login .login .form-control::placeholder {
  color: #2d2d2d;
  opacity: 0.5;
}

.syllabus_login .login .btn {
  padding: 8px 15px;
  font-size: var(--font-medium-size);
  font-weight: var(--font-weight-semibold);
  border-radius: var(--btn-border-radius);
  background: var(--btn-sky-blue);
  border: 1px solid var(--btn-sky-blue);
}

.syllabus_login .login .btn:hover {
  background: var(--btn-dr-blue);
  border: 1px solid var(--btn-dr-blue);
}

.syllabus_login .login .btn-link,
.syllabus_login .login .btn-link:hover {
  width: 100%;
  padding: 8px 10px 20px;
  text-align: center;
  background: transparent;
  border: none;
  text-decoration: none;
  color: var(--btn-sky-blue);
  font-weight: var(--font-weight-bold);
}

/*Success message*/
.success_message {
  padding: 30px 20px 25px;
  width: 100%;
  text-align: center;
}

.success_message img {
  width: 80px;
  margin-bottom: 20px;
}

.success_message .btn {
  margin-top: 20px;
}

.SuccessMessage,
.SuccessFormMessage {
  background: #3ed1a4;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
}

.SuccessFormMessage {
  position: relative;
  padding: 10px;
  margin-top: -43px;
}

.errorMsg,
.errorMsg_otp {
  background: #fff0f0;
  color: var(--dark-red-color);
  width: 100%;
  text-align: left;
  position: relative;
  top: -12px;
  padding-left: 5px;
}

.errorMsg_otp {
  top: 0;
  font-size: var(--font-small-size);
  margin: -11px 0px 10px;
  display: block;
}

/*******************************************************************************************************************************************/
/*     Book your slot     *******************************************************************************************************************/
/*******************************************************************************************************************************************/
.bys_body {
  background: transparent;
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bys_card {
  width: 100%;
  max-width: 450px;
  border-radius: 5px;
}

.bys_heading {
  text-align: center;
  width: 100%;
}

.bsy_btn {
  width: 100%;
}

.bys_form {
  width: 100%;
  border-radius: 0px 0px 8px 8px;
}

.SuccessFormMessage {
  color: green;
  text-align: center;
  margin-top: 10px;
}

.bys-close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1.5px solid #a4a4a3;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9;
}

.bys-close-modal span {
  font-size: 25px;
  color: #a4a4a3;
  line-height: 1;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

/*******************************************************************************************************************************************/
/*     Media Queries     *******************************************************************************************************************/
/*******************************************************************************************************************************************/
@media screen and (max-width: 667px) {
  body {
    overflow-x: hidden !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  /* * {
    outline: 1px solid red;
  } */
  body {
    overflow-x: hidden !important;
  }

  .row {
    margin: 0;
    width: 100%;
  }

  .navbar .navbar-toggler {
    outline: none;
    box-shadow: none;
  }

  /* .navbar {
    top: 0;
    background-color: transparent;
  } */
  .top-nav-collapse {
    background-color: var(--bg-white-color);
  }

  .offcanvas-collapse.show {
    position: relative;
    top: 15px;
    left: 0;
    visibility: visible;
    padding: 0px 15px 15px;
    margin-bottom: 15px;
  }

  .header {
    padding-top: 3rem;
  }

  .header .p-large,
  .banner-input-field {
    width: 100%;
  }

  .header .image-container {
    margin-top: 1rem;
  }
  .banner-tick ul {
    margin: 10px 0 0;
    padding: 0;
  }

  /* Banner Carousel */
  #bannerCarousel {
    width: 18rem;
    margin-top: 20px;
    margin: 20px auto;
    position: relative;
    bottom: 25px;
    left: auto;
    right: auto;
  }

  #bannerCarousel .carousel-item .rounded-circle {
    width: 80px;
    height: 80px;
    left: -35%;
  }

  #bannerCarousel .carousel-caption {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    left: 33%;
  }

  .carousel-caption h6 {
    font-size: 16px !important;
    margin-bottom: 20px;
    padding-bottom: 5px;
    line-height: 0%;
  }

  .carousel-caption small {
    padding-top: 0px;
    padding-bottom: 10px;
    margin-top: 0px;
    font-size: 14px !important;
    line-height: 1%;
    margin-bottom: 0px;
  }

  .seemore-btn {
    position: absolute;
    padding: 5px;
    right: 3%;
    margin-bottom: 0px;
    bottom: 8%;
  }

  .review-bg {
    right: -8px;
    top: -80px;
  }

  .review-girl {
    width: 100%;
  }

  .common-section {
    padding-top: 100px;
    padding-bottom: 0px;
  }

  .review-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  #details.common-section .review-main .image-container img {
    display: inline-block;
    opacity: 1;
  }

  .common-section h2 {
    text-align: center;
  }

  .review-main .image-container img {
    width: 70%;
    margin: 0 auto;
    display: flex;
    position: relative;
    opacity: 0.2;
  }
  .access-courses {
    height: 350px;
  }
  .access-courses .course_points {
    position: absolute;
    left: 0px;
  }

  .access-courses .course_points h2 {
    text-align: center;
  }

  .access-courses .banner-tick li {
    line-height: 30px;
  }

  .text-container {
    padding-top: 15px;
  }

  .header .p-large {
    padding: 15px 0px;
  }

  .header .h1-large,
  h2 {
    width: 100%;
    font-size: var(--font-xl-size);
  }

  .cards-1 h2 {
    width: 100%;
    text-align: center;
  }

  h2 br {
    display: none;
  }

  .h1-bg {
    right: 0%;
    top: -22%;
    display: none;
  }

  .common-section .d-flex.align-items-center.mb-3 {
    margin-bottom: 6px !important;
  }

  .text-container .des-txt {
    margin: 15px 0px;
  }

  .banner-tick li {
    text-align: left;
    padding: 5px 0px 5px 35px;
    line-height: 30px;
    background-size: 8%;
  }

  .access-courses .banner-tick li:first-child {
    background-position: left 30%;
    line-height: 1.7;
  }

  .text-container .h1-large br {
    display: none;
  }

  .most-popular-content .text-container,
  .cards-2 .des-txt {
    padding: 0;
  }

  .course-box-item {
    left: 33px;
    width: 80%;
    bottom: -40px;
  }

  .cards-2 {
    text-align: center;
    padding: 30px 0px 0px 0px;
  }

  .cards-2 .des-txt {
    padding: 15px 0px;
  }

  .tabs-nav {
    padding: 20px;
    display: flex;
    overflow: auto;
  }

  .tabs {
    padding-bottom: 20px;
  }

  .specialized-services {
    height: 100%;
    padding: 20px;
  }

  .specialized-services .des-txt {
    line-height: 1.5;
  }

  .specialized-services .container {
    padding: 0;
  }

  .specialized-services .text-container,
  .spec-position .col-lg-3 {
    padding: 0;
  }

  .spec-position {
    position: relative;
    height: 100%;
  }
  .service-main {
    background-color: white;
    margin: 10px 0px 0px 0px;
  }

  .service-card {
    padding: 15px 20px;
  }

  .feature-block {
    padding-bottom: 40px;
  }

  .student-txt::after {
    left: 0;
  }

  .content.active-content .col-lg-3 {
    padding-left: 0 !important;
  }

  .browse-blc a {
    float: none;
    margin-top: 10px;
  }

  .most-popular-content {
    text-align: center;
  }

  .most-popular-teacher {
    height: 100%;
    position: relative;
    background: transparent;
  }

  .most-popular-teacher::after {
    background: url(../images/most-popular-teacher.png) no-repeat;
    background-position: 0% 16%;
    height: 400px;
    width: 100%;
    content: "";
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-size: cover;
  }

  .most-popular-content .text-container .des-txt {
    color: #000;
    font-weight: 600;
  }

  .counter-container {
    display: block;
  }

  .counter .counter-cell {
    margin-bottom: 25px;
  }

  .counter .purecounter {
    margin-bottom: 0px;
    font-size: var(--font-xxl-size);
  }

  .social-activities {
    padding: 0px 0px 0px;
  }

  .social-activities .col-lg-3 {
    margin-bottom: 20px;
  }

  .social-activities .col-lg-9 {
    padding: 0;
  }

  .cards-3 {
    padding: 0px 0px 0px;
    text-align: center;
  }

  .post-img img {
    width: 100%;
  }

  .social-a {
    margin-bottom: 0;
  }

  .contact-form .h1-large {
    margin: 0;
    width: 100%;
    font-size: var(--font-xl-size);
    padding: 10px 0px;
  }

  .form-divider {
    padding: 0;
  }

  .footer-contact,
  .footer-links {
    width: 100%;
    padding: 0px 20px;
  }

  .social-links {
    top: 0;
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .footer-top {
    padding: 30px 15px;
  }

  .footer-top .container {
    padding: 0;
  }

  .footer-contact p {
    padding-bottom: 15px;
  }

  .footer-bottom .text-center {
    text-align: left !important;
  }

  .copyright a {
    padding: 0px 15px 0px 0px;
  }

  .service-card p {
    font-size: var(--font-large-size);
  }

  /*************************/
  /*   Course Page Mobile  */
  /*************************/
  .course-tabs .tabs-nav {
    top: -63px;
    width: 100%;
  }
  .tabs-nav-wdt {
    width: 100%;
  }

  .pagination {
    overflow: auto;
  }

  .tabs-class-heading2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .tab-h-left .tabs-flex {
    flex-direction: column;
    align-items: flex-start !important;
  }

  /* .upcoming-batch table td.c-td .btn.float-end {
    right: 0;
    top: 10px;
  } */

  .page-link {
    margin-bottom: 10px;
  }

  .access-courses {
    padding: 15px 0px;
  }

  /* .upcoming-schedule-training {
    top: 0px;
    padding: 15px 0px;
  }

  /* .upcoming-batch .des-txt,
  .upcoming-batch h2 {
    padding-bottom: 15px;
  }

  .upcoming-batch .des-txt {
    text-align: center;
  }

  .upcoming-batch table td {
    padding: 0.5rem 0.5rem;
    vertical-align: top;
    font-size: var(--font-medium-size);
  } */

  .btn {
    padding: 5px;
  }

  .placement-banner-img img {
    position: relative;
    top: -2%;
    width: 20rem;
    max-height: 15rem;
    margin: 0px;
    padding: 0px;
    object-fit: cover;
  }

  .course-ctg {
    padding: 20px;
    padding-top: 50px;
  }

  .course-content-1 ul {
    display: inline-block !important;
  }

  .course-tabs .content-tabs {
    margin-top: 0px;
  }

  .course-dt.course-rv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }

  .course-dt {
    padding-bottom: 10px;
  }

  .course-details-tabs .tabs-nav .tabs {
    padding: 0px 10px;
  }

  .modal.show .modal-dialog,
  .modal-dialog {
    max-width: 100%;
  }

  /* .modal.youtube_player .modal-body > div > div,
  .modal.youtube_player .modal-body > div,
  .modal.youtube_player .modal-content {
    height: auto !important;
  } */

  #clients {
    height: 100%;
  }

  .play {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0;
    right: 15px;
    bottom: -20px;
  }

  .feature-block .col-lg-4 {
    margin-bottom: 0px !important;
  }

  .text-h6::after,
  .got-placed .text-h6::after {
    width: 100%;
  }

  span.play-button .play_icon {
    position: relative;
    right: 5px;
  }

  span.play-button {
    height: 30px;
    top: -10px;
    font-size: var(--font-xxl-size);
    float: none;
    right: 0;
  }

  .got-placed {
    padding-bottom: 30px;
  }

  .got-placed p,
  .got-placed .text-h5 {
    margin: 0px;
  }

  .got-placed .d-flex {
    justify-content: center;
  }

  .got-placed .d-flex .ms-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
  }

  .feature-block h2 {
    padding: 15px;
  }

  .h1-large {
    font-size: var(--font-xxl-size);
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  .workshop-detail-heading .h1-large {
    font-size: var(--font-xl-size);
    padding: 0px 0px 20px 0px;
  }

  .workshop-detail-banner {
    padding-bottom: 20px;
    padding: 10px;
  }
  .workshop-detail-banner img {
    height: 300px;
  }

  .social_detail_view .col-lg-5 img {
    height: 300px;
  }
  .h1-large br {
    display: none;
  }

  .common-block {
    padding: 0px 0px 15px;
    text-align: center;
  }

  .course-content-1 li,
  .common-block li {
    text-align: left;
    padding-bottom: 5px;
  }

  .common-block .des-txt {
    padding-bottom: 15px;
  }

  .course-content-1 li::before,
  .common-block li::before {
    top: 7px;
  }

  .placement-details {
    margin-top: 15px;
  }

  .trust-des .des-txt {
    padding: 4rem 15px 0px !important;
    text-align: center;
  }

  .recent-placed-students-details {
    padding-top: 15px;
  }

  .course-details-tabs .tabs-nav {
    width: 100%;
    top: 0;
    margin-bottom: 15px;
  }

  .common-crs-details .des-text {
    text-align: center;
    line-height: 26px;
    margin-bottom: 15px !important;
  }

  .button-float-right {
    position: relative;
    top: 10px;
  }

  .course-content {
    padding: 0px 0px 0px;
  }

  .course-content .course-content-1 {
    padding-bottom: 15px;
  }

  .course-download-file {
    width: 100%;
    margin-bottom: 20px;
  }

  .button-float-right .btn {
    width: 100%;
  }

  .tab-accordions .search-course {
    position: relative;
    right: 0;
    top: 10px;
    margin-bottom: 15px;
  }

  .tab-accordions .accordion-body p {
    background-size: 7%;
  }

  .sub-banner,
  .course-detail-sub-banner {
    min-height: 18rem;
    padding-top: 40px;
    font-size: var(--font-xxxl-size);
  }

  .workshop-tabs .tabs-nav {
    top: 0px;
  }

  .event-card {
    flex-direction: column;
  }

  .right_side_event h3 {
    margin: 15px 0px;
    line-height: normal;
  }

  .right_side_event span {
    margin-bottom: 15px;
  }

  button.btn.btn-purple {
    padding: 0.3rem 0.8rem;
  }

  .date_time {
    font-size: var(--font-small-size);
    margin-right: 0 !important;
  }

  .event-card:last-child {
    margin-bottom: 20px;
  }

  .form-container.keyword_select {
    padding: 0;
  }

  .find_workshop {
    margin-bottom: 30px;
  }

  .tabs-flex p {
    margin-right: 10px !important;
  }

  .h3-medium {
    font-size: var(--font-xl-size);
    margin: 0px;
  }

  .common-block img {
    width: 100%;
  }

  .bg-holder {
    position: relative;
    width: 90%;
    height: 30vh;
    margin: 0 auto;
  }

  .mobile_vision {
    padding: 0 10px !important;
  }

  .vision_box {
    display: flex;
    flex-direction: column;
  }

  .vision_text {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }

  .profile_content img {
    width: 100%;
    text-align: center;
  }

  .profile_content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .adminssion-year {
    padding: 15px;
  }

  .adminssion-year .btn {
    margin: 0 auto;
  }

  .mission-vision-block {
    text-align: center;
    padding: 15px 0px;
  }

  .mission-vision-block p {
    width: 100%;
  }

  .mission-vision-block h3 {
    margin-bottom: 15px;
  }

  .workshop-detail-view h3 {
    text-align: center;
    margin-bottom: 20px !important;
  }

  .vision_container {
    margin: 0px;
  }

  .expert_instructor {
    padding: 15px 0px;
  }

  .expert_instructor h3,
  .text-h6 {
    margin-bottom: 15px;
    text-align: center;
  }

  .profile_details {
    margin-bottom: 15px;
    flex-direction: column;
  }

  .expert_instructor p {
    text-align: center;
  }

  .team_member_info {
    padding: 15px 0px;
  }

  .team_member_detail {
    flex-direction: column;
  }

  .progress_container {
    top: 0;
  }

  .active-content {
    padding: 0px;
  }

  .rl_course {
    padding: 0;
  }

  .progress_rating {
    margin: 15px 0px;
  }

  .message_sec {
    padding: 0 12px;
  }

  .comment_container {
    padding: 20px 0px;
    margin-bottom: 50px;
  }

  .form-container .form-control {
    margin-bottom: 15px;
  }

  .comment_box .btn {
    width: 100px;
    justify-content: center;
    margin-right: 10px;
  }

  .comment_box span,
  .rate_us {
    padding-left: 15px;
  }

  .contact-form {
    padding: 10px 10px 20px;
  }

  .contact-form .container {
    padding: 0;
  }

  .contact-form h1-large {
    line-height: var(--line-height-h1);
  }

  .common-img-2 img {
    width: 200px;
    display: flex;
    margin: 0 auto 20px auto;
  }

  .course-detail-heading h2 {
    padding: 0;
  }

  .common-crs-details .d-flex {
    flex-direction: column;
    padding: 20px;
  }

  .common-crs-details h5,
  .common-crs-details h6 {
    text-align: center;
  }

  .common-block-3 {
    padding: 0px;
  }

  .faq-thumb {
    position: absolute;
    right: -25px;
    top: -18px;
  }

  .tab-accordions.faq-accordions .accordion-body p {
    padding-right: 0;
  }

  .contact-form h1-large br {
    display: none;
  }

  .content .skill-title {
    font-size: var(--font-large-size);
    left: -60px;
    top: 17px;
  }

  .rating_section {
    width: 100%;
  }

  .bar-container {
    width: 85%;
  }

  .progress-bar {
    width: 90%;
  }

  .percentage {
    font-size: var(--font-large-size);
    right: -40px;
  }

  .cards-3 h2 {
    padding: 20px 0px 10px;
  }

  .comment_box .form-container .btn-sky-blue {
    top: 0px;
  }

  .team_member_info h3 {
    text-align: center;
  }

  .tr_pr_detail {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  .text-container {
    z-index: 1;
    text-align: center;
  }

  .icons-bg {
    width: 30px;
    height: 30px;
  }

  .course-white-b img {
    width: 78%;
    margin-top: 40px;
  }

  .modal.syllabus_modal .modal-body .syllabus_content {
    border-radius: 0;
  }

  .syllabus_modal .modal-dialog-centered {
    min-height: calc(100vh - 200px);
  }

  .syllabus_login {
    background: #fff;
    padding-bottom: 20px;
  }

  .syllabus_login .heading {
    padding: 15px;
  }

  .syllabus_login .login {
    padding-bottom: 15px;
  }

  .syllabus_content img {
    height: 150px;
  }

  .modal.syllabus_modal .modal-body .syllabus_content {
    height: 250px;
  }

  .sub-banner,
  .placement-page .sub-banner {
    height: 150px;
  }

  .workshop-page h1,
  .about-us h1 {
    font-size: var(--font-xl-size);
  }

  .left_side_event {
    padding: 0px;
  }

  .left_side_event img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .sub-banner-white,
  .common-block h2 {
    padding: 15px 0px;
  }

  .team_profile .tr_pr_detail {
    padding: 10px 0px 20px;
  }

  .course-details-tabs .tabs-nav .tabs.active-tab::after {
    left: 10px;
    width: 80%;
  }
  .list-group-horizontal {
    overflow: hidden;
  }
}

/******************************************************************************************************/
/****Ipad and tablet****/
/******************************************************************************************************/
@media (min-width: 481px) and (max-width: 768px) {
  body {
    overflow-x: hidden !important;
  }

  .container {
    max-width: 100% !important;
  }
  .social-activities .col-lg-3 {
    margin-bottom: 20px;
  }

  .row {
    margin: 0px;
  }

  .navbar .navbar-toggler {
    outline: none;
    box-shadow: none;
  }

  .header .image-container {
    margin-top: 30px;
  }

  /* .navbar {
    top: 0;
    background-color: transparent;
  } */
  .top-nav-collapse {
    background-color: var(--bg-white-color);
  }

  .offcanvas-collapse.show {
    position: relative;
    top: 15px;
    left: 0;
    visibility: visible;
    padding: 15px;
    margin-bottom: 15px;
  }

  /* *************** */
  /* workshop-page quaries */
  /* *********** */
  .event-card {
    display: block;
    display: grid;
    place-items: center;
  }
  .right_side_event {
    margin-top: 1rem;
  }
  .right_side_event h3 {
    width: 320px;
  }
  .right_side_event span {
    width: 320px;
  }

  /* .left_side_event img {
    width: 100%;
    
} */

  /* .timer{
    justify-content: center;
    display: flex;
    align-items: center;
  } */

  .left_side_event {
    padding-right: 0px;
  }

  .navbar {
    background: var(--bg-white-color);
  }

  .h1-large br {
    display: none;
  }

  .header .h1-large {
    display: block;
    padding-top: 0px;
    width: 100%;
  }

  .banner-tick li {
    text-align: left;
  }

  #JavaHomeHeader .p-large,
  .banner-input-field {
    width: 100%;
  }

  #bannerCarousel {
    width: 19rem;
    margin-left: 20%;
    margin-right: 30% !important;
    left: auto;
    right: auto;
    height: auto;
  }
  #bannerCarousel .carousel-item .rounded-circle {
    left: -35%;
    height: 80px;
    width: 80px;
  }
  #bannerCarousel .carousel-caption {
    padding-top: 10px;
    position: absolute;
    display: flex;
    left: 33%;
    right: 0;
  }
  #bannerCarousel .carousel-item h6 {
    font-size: var(--font-large-size);
  }
  #bannerCarousel small {
    font-size: var(--font-medium-size);
  }

  .course-detail-heading nav {
    padding: 0;
  }
  .seemore-btn {
    position: absolute;
    right: 3%;
    bottom: 6%;
    padding: 5px;
  }
  .btn-mpc {
    margin-top: 10px;
  }
  .client-logos-txt,
  .client-logos,
  .review-girl {
    width: 100%;
  }

  .review-bg {
    left: 60%;
    right: 0%;
    top: 0px;
  }

  .course-white-b img {
    width: 100%;
    left: 0px;
    width: 347px;
    height: 260px;
  }

  .cards-2 .des-txt,
  .most-popular-content .text-container {
    padding: 0;
  }

  /* .most-popular-content .text-container{
    padding-bottom: 20px;
  } */
  .specialized-services {
    height: 100%;
    padding: 40px 20px;
  }

  .star-review-bg {
    padding-top: 20px;
  }

  .most-popular-content {
    padding: 0;
  }

  .specialized-services .container {
    padding: 0;
  }

  .spec-position {
    position: relative;
    top: 0;
    height: 100%;
  }

  @media screen {
  }

  /* .service-card {
    margin-bottom: 20px;
  } */

  .text-container .des-txt {
    margin: 10px 0px;
    color: #000;
  }

  .common-section h2 {
    padding: 20px 0px;
  }

  .common-section h2 br {
    display: none;
  }

  .cards-1 h2 {
    padding-top: 50px;
  }

  .cards-1 h2 br {
    display: none;
  }

  .h1-bg {
    right: 0%;
    top: -7%;
    width: 18%;
    float: right;
    transform: rotate(-20deg);
    display: none;
  }

  .text-container {
    z-index: 1;
  }

  .counter .purecounter {
    line-height: 1;
    font-size: var(--font-x-size);
  }

  .counter .counter-info {
    font-size: var(--font-medium-size);
  }

  .counter-cell:first-child .purecounter,
  .counter-cell:nth-child(2) .purecounter {
    padding: 0;
  }

  .cards-3 .h1-large {
    padding: 0;
  }

  .browse-blc a {
    font-size: var(--font-x-size);
  }

  .post-img img {
    width: 100%;
  }

  .social-a {
    margin-bottom: 50px;
  }

  .contact-form .h1-large {
    margin-top: 0;
    width: 100%;
    font-size: var(--font-xl-size);
    padding: 0 0px 20px;
  }

  .contact-form {
    padding: 50px 0px;
  }

  .sub-banner,
  .course-detail-sub-banner {
    padding-top: 120px;
  }

  .got-placed .d-flex {
    align-items: center;
  }
  .search-course {
    top: 50px;
    right: 5%;
  }

  .review-main .image-container {
    text-align: center;
    padding-bottom: 30px;
  }

  /* .upcoming-batch table td.c-td .btn.float-end {
    top: 4px;
    right: 0;
  } */

  .course-box-item {
    max-width: 55%;
    left: 170px;
    bottom: -2%;
  }

  .cards-2 h2 br {
    display: none;
  }

  .cards-2 .des-txt {
    padding: 10px 0px;
  }

  .course-white-b img {
    width: 100%;
    height: 100%;
  }

  .tabs-nav {
    width: auto;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px;
  }

  .course-tabs .tabs-nav {
    padding: 0px 15px;
    top: -60px;
    width: 100%;
  }
  .tabs-nav-wdt {
    width: 100%;
  }

  .tabs-nav .tabs {
    margin-right: 30px;
    padding: 15px 0px;
  }

  .tabs-nav .tabs:last-child {
    margin: 0;
  }

  /* .tabs:hover {
    transform: translateX(10px);
  } */

  .tab-content-image img {
    width: 100%;
    height: auto;
    max-height: 200px;
  }

  .placement-banner-img img {
    position: relative;
    top: 0px;
    width: 20rem;
    margin: 0px 0px 15px 0px;
    object-fit: cover;
  }

  .course-details-tabs .tabs-nav {
    width: 100%;
  }

  .course-dt h6 {
    font-size: var(--font-xl-size);
  }

  .course-dt h4 {
    font-size: var(--font-xxl-size);
    margin-bottom: 20px;
  }

  .course-dt.course-rv {
    padding: 0;
  }

  .button-float-right {
    position: relative;
    padding: 10px;
    justify-content: center;
    display: flex;
  }

  .course-download-file {
    margin-bottom: 20px;
  }

  .button-float-right .btn {
    padding: 10px;
  }

  .form-container .input_sec {
    padding-left: 0;
  }

  .comment_box .form-container .btn-sky-blue {
    top: -12px;
  }

  .tab-accordions .accordion-body p {
    background-size: 3%;
  }

  .course-detail-heading h2 {
    padding: 0;
  }

  .trust-des .des-txt {
    padding: 2.5rem 15px 0px !important;
    text-align: center;
  }

  .workshop-tabs .tabs-nav {
    top: -30px;
  }

  .about-us .sub-banner {
    padding: 100px 20px 0px 20px;
  }

  .adminssion-year,
  .mission-vision-block {
    padding: 30px;
  }

  .mission-vision-block .container,
  .mission-vision-block .col-lg-6 {
    padding: 0;
  }

  /* .workshop-tabs .tabs-nav .tabs {
    padding: 0px 10px 5px 10px;
    margin-right: 60px;
  } */

  .event-card:last-child {
    margin-bottom: 30px;
  }

  .find_workshop .input_search input {
    width: 100%;
  }

  .find_workshop {
    margin-bottom: 30px;
  }

  .find_workshop .form-container select.form-control.form-select {
    border: 1px solid var(--border-color);
  }

  .form-container.keyword_select .form-select {
    border: 1px solid var(--border-color);
    height: 37px;
    border-radius: 4px;
  }

  .team_member_detail {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .expert_instructor {
    padding-bottom: 0;
  }

  .team_member_info {
    padding-top: 0;
  }

  .expert_instructor h3 {
    padding: 0px 30px;
    margin: 0;
  }

  .expert_detail {
    padding: 30px;
  }

  .expert_detail .col-lg-7 {
    padding: 0;
  }

  .expert_instructor .container {
    padding: 0;
  }

  .profile_details {
    justify-content: flex-start;
    padding-bottom: 30px;
    align-items: center;
  }

  .team_member_info h3 {
    padding: 20px 0px 0px 20px;
  }

  .bg-holder {
    position: relative;
    height: 490px;
    width: 100%;
  }

  .common-block img {
    width: 100%;
  }

  .progress_rating,
  .comment_container {
    margin-bottom: 40px;
  }

  .footer-contact {
    width: 50%;
  }

  .bar-container {
    width: 85%;
  }

  .progress-bar {
    height: 16px;
  }

  .play {
    right: 110px;
  }
  .dev-tag {
    width: 57%;
    margin-left: 0px;
  }
}

/* internship */
.h1-large {
  font-size: 32px;
}

.section-title {
  font-size: 28px;
}

.benefits-list,
.phase-card p {
  font-size: 16px;
}

.combined-phases {
  flex-direction: column;
}

/* Min-width 768px */
@media (min-width: 768px) {
  /* Header */
  .header {
    padding-top: 5rem;
    padding-bottom: 5em;
  }

  .header .decoration-star {
    top: 100px;
    left: -150px;
    width: 300px;
  }

  .header .decoration-star-2 {
    top: 140px;
    right: -200px;
    width: 400px;
  }
  /* end of header */

  /* Statistics */
  .counter .counter-cell {
    display: inline-block;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    vertical-align: top;
  }
  /* end of statistics */

  /* Services */
  .cards-1 .card {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  /* end of services */

  /* Extra Pages */
  .ex-basic-1 .text-box {
    padding: 1.75rem 2rem 0.875rem 2rem;
  }
  /* end of extra pages */
}

@media (max-width: 768px) {
  .navbar .navbar-brand img {
    height: 30px;
  }
}
/* end of min-width 768px */

/* Min-width 992px */
@media (min-width: 992px) {
  /* General Styles */
  .h2-heading {
    width: 35.25rem;
    margin-right: auto;
    margin-left: auto;
  }

  .p-heading {
    width: 46rem;
    margin-right: auto;
    margin-left: auto;
  }
  /* end of general styles */

  /* Navigation */
  .navbar {
    padding-top: 10px;
    box-shadow: none;
    transition: all 0.2s;
  }

  .navbar.extra-page {
    padding-top: 0.5rem;
  }

  .navbar.top-nav-collapse {
    padding: 10px 0;
    background-color: var(--bg-white-color);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  }

  .offcanvas-collapse {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    width: auto;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    overflow-y: visible;
    visibility: visible;
  }

  .offcanvas-collapse.open {
    -webkit-transform: none;
    transform: none;
  }

  .navbar .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }

  .navbar .dropdown-menu {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.08);
  }

  .navbar .dropdown-divider {
    width: 90%;
  }

  .navbar .nav-item .btn-outline-sm {
    margin-top: 0;
    margin-left: 1rem;
  }
  /* end of navigation */

  /* Header */
  .header {
    text-align: left;
  }

  .header .image-container {
    margin-top: 0;
  }

  .header .btn-solid-lg,
  .header .btn-outline-lg {
    margin-right: 0;
    margin-left: 0;
  }

  .header .btn-solid-lg {
    margin-right: 0.5rem;
  }
  /* end of header */

  /* Statistics */
  .counter .purecounter {
    font-size: var(--font-xxl-size);
  }
  /* end of statistics */

  /* Introduction */
  .basic-1 h2 {
    width: 90%;
  }
  /* end of introduction */

  /* Details 1 */
  .basic-2 .decoration-star {
    top: 260px;
    left: -150px;
    width: 300px;
  }

  .basic-2 .image-container {
    margin-bottom: 0;
  }
  /* end of details 1 */

  /* Services */
  .cards-1 .text-container {
    margin-bottom: 0;
  }
  /* end of services */

  /* Details 2 */
  .basic-3 .decoration-star {
    top: 260px;
    right: -150px;
    width: 300px;
  }

  .basic-3 .image-container {
    text-align: right;
  }
  /* end of details 2 */

  /* Invitation */
  .basic-4 h4 {
    width: 48rem;
    margin-right: auto;
    margin-left: auto;
  }
  /* end of invitation */

  /* Extra Pages */
  .ex-cards-1 .card {
    display: inline-block;
    width: 296px;
    vertical-align: top;
  }

  .ex-cards-1 .card:nth-of-type(3n + 2) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  /* end of extra pages */
}

.dev-tag {
  margin-left: 8px;
}

/* Media query for bannercarousel in home page header */
@media (min-width: 769px) and (max-width: 991px) {
  .navbar {
    background: var(--bg-white-color);
  }
  .course-tabs .tabs-nav {
    position: relative;
    top: -70px;
    width: 709px;
    /* overflow: scroll; */
  }
  .tabs-nav-wdt {
    overflow: scroll;
    margin-left: -26px;
  }
  .counter-container {
    width: 100%;
    display: block;
  }

  #bannerCarousel {
    width: 23rem;
  }
  #bannerCarousel .carousel-item .rounded-circle {
    left: -35%;
    width: 100px;
    height: 100px;
  }
  #bannerCarousel .carousel-caption {
    padding-top: 15px;
    left: 60px;
  }
  .carousel-caption h6 {
    font-size: var(--font-l-size) !important;
  }
  .carousel-caption small {
    font-size: var(--font-large-size);
  }
  .tabs {
    font-size: var(--font-large-size);
  }
  .mpc-dec {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .h1-bg {
    right: 47%;
    top: -35%;
  }

  .placement-banner-img img {
    position: relative;
    top: -10px;
    object-fit: cover;
    width: 20rem;
    margin: 0px 0px 5px 0px;
  }
  .sub-banner,
  .course-detail-sub-banner {
    padding-top: 120px;
  }

  .most-popular-content {
    padding: 1% 0px;
  }
  .star-review-bg h4 {
    padding-top: 1%;
  }
  .text-container .des-txt {
    color: black;
  }
  .text-container .des-txt {
    margin: 8px 1px;
  }

  .upcoming-batch table td.c-td .btn.float-end {
    position: static;
  }

  .tab-item {
    margin-right: 10px;
    height: 300px;
  }

  .header#JavaHomeHeader .text-container {
    align-items: center;
  }
  .input_search,
  .search-input-box {
    width: 89%;
  }
  .search-course span {
    right: 23px;
  }
  .contact-form .h1-large {
    margin: 26% 0 10px 0;
  }
  .banner-tick ul {
    text-align: justify;
  }
  .input_search span {
    right: 67px;
    top: 0px;
  }
  #courseName {
    width: 79%;
  }
  .dev-tag {
    width: 60%;
    margin-left: 0px;
  }
  .dev-tag-text {
    width: 8%;
  }
}

@media (min-width: 991px) {
  .navbar {
    background: var(--bg-white-color);
  }
  #bannerCarousel {
    width: 23rem;
  }
  #bannerCarousel .carousel-item .rounded-circle {
    left: -35%;
    width: 100px;
    height: 100px;
  }
  #bannerCarousel .carousel-caption {
    padding-top: 15px;
    left: 60px;
  }
  .carousel-caption h6 {
    font-size: var(--font-l-size) !important;
  }
  .most-popular-content .text-container .des-txt {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .h1-bg {
    right: 47%;
    top: -35%;
  }
  .placement-banner-img img {
    position: relative;
    top: -10px;
    object-fit: cover;
    width: 20rem;
  }
  .sub-banner,
  .course-detail-sub-banner {
    padding-top: 120px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  #bannerCarousel {
    width: 18rem;
  }
  #bannerCarousel .carousel-item .rounded-circle {
    left: -3%;
  }
  #bannerCarousel .carousel-caption {
    position: absolute;
    left: 90px;
  }
  .seemore-btn {
    right: 2%;
    bottom: 5%;
    padding: 6px;
  }

  .h1-bg {
    right: 19%;
    top: -34%;
  }
  .star-review-bg {
    padding-left: 50%;
  }
  .course-tabs .tabs-nav {
    position: relative;
    top: -80px;
    width: 950px;
    overflow-x: scroll;
  }
  .placement-banner-img img {
    position: relative;
    object-fit: cover;
  }
  .sub-banner,
  .course-detail-sub-banner {
    padding-top: 120px;
  }
  /* .search-style span {

    top: 20px;
  
  } */
}
/* -------------------------------- */
/* end of min-width 992px */

@media (min-width: 1200px) {
  .navbar .nav-item .nav-link {
    padding-right: 38px;
  }
  #bannerCarousel {
    width: 20rem;
  }
  #bannerCarousel .carousel-item .rounded-circle {
    left: 0%;
  }
  #bannerCarousel .carousel-caption {
    position: absolute;
    left: 90px;
  }

  .social-links {
    right: -20px;
  }

  .h1-bg {
    position: absolute;
    right: 43%;
    top: -35%;
  }

  .tab-item {
    max-height: 305px;
  }

  .star-review-bg {
    padding-left: 30%;
  }
  .placement-banner-img img {
    position: relative;
    object-fit: cover;
  }

  /* General Styles */
  /* .container {
    max-width: 1200px;
    padding: 0 0.17rem;
  } */
  /* end of general styles */
  .sub-banner,
  .course-detail-sub-banner {
    padding-top: 135px;
  }

  /* .diff-style {
    top: 27px;
  } */
  /* .search-course span {
    top: 27px;
  } */
}

@media screen and (min-width: 1400px) and (max-width: 1768px) {
  /* .search-style span {
    top: 28px;
  } */
  .date_picker span {
    right: 147px;
  }
}
@media screen and (min-width: 1768px) and (max-width: 2652px) {
  /* .search-style span {
    top: 28px;
  } */
  .date_picker span {
    right: 147px;
  }
}

/* Min-width 1200px */
@media (min-width: 1400px) {
  /* General Styles */
  .container {
    max-width: 1200px;
  }
  /* end of general styles */

  /* Header */
  .header {
    padding: 5rem;
  }
  .rate h6 {
    bottom: 10px;
  }
  .h1-bg {
    right: 32%;
    top: -33%;
  }
  .header .decoration-star {
    top: 200px;
    left: -200px;
    width: 500px;
  }
  .header .decoration-star-2 {
    top: 150px;
    right: -200px;
    width: 600px;
  }
  .header .image-container {
    text-align: right;
  }
  .course-box-item {
    left: 80px;
  }
  /* end of header */

  /* Statistics */
  .counter .counter-cell {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .counter .counter-cell:first-of-type {
    margin-left: 0;
  }
  .counter .counter-cell:last-of-type {
    margin-right: 0;
  }
  .counter .purecounter {
    font-size: var(--font-xxl-size);
  }
  /* end of statistics */

  /* Details 1 */
  .basic-2 .decoration-star {
    top: 140px;
    left: -200px;
    width: 500px;
  }
  .basic-2 .text-container {
    margin-top: 5rem;
    margin-left: 2.5rem;
  }
  .basic-2 h2 {
    width: 90%;
  }
  /* end of details 1 */

  /* Services */
  .cards-1 .text-container {
    margin-top: 2rem;
    margin-right: 8.75rem;
  }
  .cards-1 .card {
    margin-right: 2.25rem;
    margin-left: 2.25rem;
  }
  .cards-1 .card:nth-of-type(3n + 1) {
    margin-left: 0;
  }
  .cards-1 .card:nth-of-type(3n + 3) {
    margin-right: 0;
  }
  /* end of services */

  /* Details 2 */
  .basic-3 .decoration-star {
    top: 140px;
    right: -200px;
    width: 500px;
  }
  .basic-3 .text-container {
    margin-top: 6rem;
    margin-right: 3rem;
  }
  .basic-3 h2 {
    width: 95%;
  }
  /* end of details 2 */

  /* Testimonials */
  .slider-1 .quotes-decoration {
    left: 170px;
    width: 220px;
  }
  .slider-1 .slider-container {
    width: 82%;
    margin-right: auto;
    margin-left: auto;
  }
  /* end of testimonials */

  /* Contact */

  /* end of contact */

  /* Footer */

  /* end of footer */

  /* Extra Pages */
  .ex-cards-1 .card {
    width: 336px;
  }
  .ex-cards-1 .card:nth-of-type(3n + 2) {
    margin-right: 2.875rem;
    margin-left: 2.875rem;
  }
  /* .search-course span {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 28px;
    color: var(--btn-sky-blue);
    padding: 5px 15px;
} */

  /* end of extra pages */
}

/* end of min-width 1200px */

/* Min-width 1400px */
@media (min-width: 1400px) {
  /* Header */
  /*.header .decoration-star {
		top: 120px;
		left: -250px;
		width: 600px;
	}

	.header .decoration-star-2 {
		top: 60px;
		right: -300px;
		width: 700px;
	}*/
  /* end of header */
  /* Carousel */
}
/* end of min-width 1400px */

@media (min-width: 1025px) and (max-width: 1199px) {
  .navbar .nav-item .nav-link {
    font-size: var(--font-large-size);
    padding: 5px 17px 0px 0;
  }
  .header {
    padding-bottom: 6em;
  }
  #bannerCarousel {
    width: 19rem;
  }
  #bannerCarousel .carousel-item .rounded-circle {
    left: 0px;
  }
  #bannerCarousel .carousel-caption {
    left: 100px;
  }
  .seemore-btn {
    margin-bottom: -1%;
  }
  /* end of Carousel */

  /* .header .h1-large {
    font-size: var(--font-xl-size);
  }
  .header .p-large {
    font-size: var(--font-medium-size);
  } */
  /* .form-control-input, .form-control-textarea{
		padding-top: 1rem;
    	padding-bottom: 1rem;
	} */
  .container {
    max-width: 960px;
    padding: 0 0.17rem;
  }

  .social-links {
    right: 30px;
  }

  .contact-form .h1-large {
    width: 100%;
  }

  .tr_pr_detail {
    padding: 0px 0px;
  }

  .h3-medium {
    font-size: var(--font-xl-size);
  }

  .h1-bg {
    position: absolute;
    right: 20%;
    top: -32%;
  }

  .tab-item {
    max-height: 415px;
  }

  .star-review-bg {
    padding-left: 45%;
  }

  .right_side_event h3 {
    font-size: var(--font-x-size);
    line-height: 1.3;
  }

  .right_side_event span {
    font-size: var(--font-large-size);
  }

  .date_time {
    font-size: var(--font-small-size);
    margin-right: 0 !important;
  }

  .tabs-flex p {
    margin-right: 5px !important;
  }

  .find_workshop {
    padding: 15px 25px;
  }

  .service-card .class-h,
  .tabs {
    font-size: var(--font-x-size);
  }

  .course-details-tabs .tabs-nav {
    width: 80%;
  }

  /* .placement-banner-img img {
    top: 18px;
  } */

  .rate h6 {
    bottom: 10px;
  }

  .search-course {
    right: 5px;
  }

  .service-card {
    padding: 25px;
  }
  .date_picker span {
    right: 72px;
  }
  .course-tabs .tabs-nav {
    top: -85px;
  }
}

/*************************/
/*   Batch modal css  */
/*************************/
.modal.batch-modal .modal-content {
  padding: 44px 32px 20px 32px;
}

.modal.batch-modal .modal-content,
.modal.batch-modal .modal-dialog,
.modal.batch-modal .modal-content .modal-header {
  background-color: #fff;
  border-radius: var(--box-border-radius);
  border: none;
  box-shadow: 0px 20px 20px #1f305d12;
  border-radius: var(--box-border-radius);
}

/* .upcoming-schedule-training-modal {
  text-align: center;
} */

.modal.batch-modal .modal-content {
  background-color: white;
}

.upcoming-popup-btn {
  position: fixed;
  width: 5%;
  left: 100%;
  top: 45%;
  padding: 5px 0px;
  transform-origin: 0% 0%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg) translateX(-50%);
}

@media (max-width: 576px) {
  #bannerCarousel .carousel-item .rounded-circle {
    font-size: 12px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .upcoming-popup-btn {
    width: 30%;
    font-size: 10px;
    padding: 5px 0;
  }

  .list-group-item {
    padding: 5px;
    font-size: 12px;
  }
  .upcoming-batch table td {
    text-align: center;
    font-size: 11px;
  }
  .upcoming-batch table td {
    padding: 0px 0px;
  }
  .upcoming-batch table td.c-td .btn.float-end {
    top: 0px;
    right: 10px;
    height: 31px;
    font-size: 11px;
    margin-bottom: 5px;
  }
  .upcoming-batch table .c-session {
    padding: 2px;
  }
  .upcoming-batch table .c-week,
  .upcoming-batch table .c-time {
    text-align: center;
    margin: 2px;
    padding-top: 7px;
  }

  .upcoming-batch table .c-week-day {
    margin-left: 19px;
    font-size: 13px;
  }
  .upcoming-schedule-training {
    position: static;
    padding: 44px 16px 20px 0px;
  }
  .upcoming-batch table td {
    padding: 6px 0px;
  }
  .banner-input-field input {
    margin-right: 88px;
    width: 68%;
    font-size: 12px;
  }
  .banner-input-field .btn-cut {
    right: 11px;
    padding: 8px 7px;
  }
  .video-btn button {
    width: 3.3rem;
    height: 31px;
    right: 14px;
    top: 43.3%;
    border-radius: 13px;
  }
  .img-fluid {
    max-width: 100%;
  }
  #bannerCarousel {
    width: 16rem;
  }
  .carousel-caption h6 {
    font-size: 15px !important;
  }

  .text-container .des-txt {
    margin: 16px 2px;
  }
  .course-align-items {
    padding-left: 13px;
  }
  .course-align-items .text-course {
    font-size: 14px;
  }
  .course-align-items .text-black {
    font-size: 12px;
  }
  .tabs-nav-wdt {
    width: 94%;
    height: 86px;
    margin-left: 0px;
    margin-top: 10px;
  }
  .tabs {
    padding-bottom: 0px;
    font-size: 15px;
  }
  .tab-item {
    margin-right: 20px;
  }
  .specialized-services .text-container,
  .spec-position .col-lg-3 {
    margin-bottom: 10px;
  }
  .service-card {
    margin-right: 13px;
  }
  .review-bg {
    right: 6px;
  }
  #details.common-section .review-main .image-container img {
    right: 17px;
  }
  .form-container {
    width: 98%;
  }
  .cards-3 h2 {
    padding: 20px 5px 10px;
  }
  .cards-2 .des-txt {
    padding: 15px 5px 0px;
  }
  .input_search,
  .search-input-box {
    width: 95%;
    margin-top: 10px;
  }
  .search-course span {
    right: 18px;
    top: 10px;
  }
  .input_search span {
    right: 6px;
    top: 11px;
  }
  .date_picker span {
    left: 157px;
  }
  .dev-tag {
    width: 34%;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .upcoming-popup-btn {
    width: 25%;
    font-size: 10px;
  }
  .text-container {
    width: 98%;
  }
  .banner-input-field input {
    width: 60%;
  }
  .banner-input-field .btn-cut {
    position: relative;
  }
  .img-fluid {
    width: 98%;
  }
  .video-btn button {
    width: 6.6rem;
    right: 7px;
  }
  .review-bg {
    left: 54%;
    right: 0%;
    top: -18px;
  }
  .review-main .image-container {
    margin-right: 5px;
  }
  .review-main .image-container img {
    width: 72%;
    margin-left: -20px;
  }
  .course-align-items {
    padding-left: 10px;
  }
  .btn-mpc {
    width: 40%;
    margin-right: 18px;
  }
  .tabs-nav-wdt {
    width: 95%;
    height: 96px;
    margin: 0px;
  }
  .tabs {
    font-size: 16px;
  }
  .tab-item {
    margin-right: 24px;
  }
  .upcoming-batch table td.c-td .btn.float-end {
    top: 0px;
    right: 0px;
  }
  .browse-blc a {
    width: auto;
    margin-right: 37px;
    margin-top: 10px;
  }
  .star-review-bg {
    padding-top: 10px;
  }

  .star-review-bg h4 {
    padding-top: 0%;
  }

  .cards-2 .des-txt,
  .most-popular-content .text-container {
    text-align: center;
  }

  .counter-container .counter-cell:last-child {
    margin-right: 7px;
  }
  .row > * {
    width: 99%;
  }
  .social-links {
    right: 143px;
    margin-top: 10px;
  }
  .specialized-services .text-container,
  .spec-position .col-lg-3 {
    margin-bottom: 10px;
  }
  .date_picker span {
    left: 159px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .upcoming-popup-btn {
    width: 18%;
  }
  .specialized-services .text-container,
  .spec-position .col-lg-3 {
    margin-bottom: 10px;
  }
  .date_picker span {
    /* pointer-events: visiblestroke; */
    left: 158px;
  }

  .find_workshop {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) {
  .upcoming-popup-btn {
    width: 15%;
  }
}

@media only screen and (min-width: 1200px) {
  .upcoming-popup-btn {
    width: 11%;
    padding: 3px;
    font-size: 13px;
  }
}
/* courses navigation media quaries */
@media screen and (min-width: 1076px) and (max-width: 1200px) {
  .tabs {
    padding: 11px 0 15px 0;
    margin-right: 27px;
  }
  /* .diff-style {
    top: 22px;
  } */
  /* .search-course span {
    top: 21px;
  } */
  /* .date_picker span {
    right: 72px;
} */
}

/*************************/
/*   Batch modal css  */
/*************************/
.modal.batch-modal .modal-content {
  padding: 44px 32px 20px 32px;
}

.modal.batch-modal .modal-content,
.modal.batch-modal .modal-dialog,
.modal.batch-modal .modal-content .modal-header {
  background-color: #fff;
  border-radius: var(--box-border-radius);
  border: none;
  box-shadow: 0px 20px 20px #1f305d12;
  border-radius: var(--box-border-radius);
}

/* .upcoming-schedule-training-modal {
  text-align: center;
} */

.modal.batch-modal .modal-content {
  background-color: white;
}

/* .upcoming-popup-btn {
  position: fixed;
  width: 12%;
  left: 100%;
  top: 45%;
  padding: 1%;
  transform-origin: 0% 0%;
  -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg) translateX(-50%);
} */

@media (min-width: 1200px) and (max-width: 1399px) {
  /* .container {
    max-width: 90%;
    width: 1320px;
  } */

  .header {
    padding-bottom: 6rem;
  }
  /* .search-style span {

    top: 28px;
  
  } */
}

@media screen and (min-width: 1366px) {
  .header .h1-large br {
    display: none;
  }

  .header .h1-large {
    width: 82%;
    line-height: var(--line-height-h1);
  }
}
@media screen and (min-width: 1400px) {
}
/*=== Animations start here  ===*/

/*==== FADE IN UP ===*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/*=== FADE IN DOWN ===*/
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/*=== FADE IN LEFT ===*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

/*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

/*=== FADE IN  ===*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/*=== FADE IN UP Big ===*/
.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/*=== FADE IN DOWN Big ===*/
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/*=== FADE IN LEFT Big ===*/
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/*=== FADE IN RIGHT Big ===*/
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
