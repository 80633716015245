
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

.container1 {
  width: 100%;
  padding: 25px;
}

.navbar {
  background-color: white;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.nav-content {
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.nav-title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(37 99 235);
}

.nav-links a {
  text-decoration: none;
  font-size: 16px;
  color: rgb(7, 7, 7);
  margin-right: 20px;
}

.nav-links a:hover {
  color: rgb(37 99 235);
}

.btn-primary {
  background-color: rgb(37 99 235);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.btn-primary:hover {
  background-color: rgb(37 99 235);
}

.hero-section {
  background-color: rgb(37 99 235);
  padding: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero-content {
  flex: 1;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  height: 450px;
  object-fit: contain;
}

.hero-section h1 {
  font-size: 64px;
  font-weight: bold;
  color: white;
}

.hero-section p {
  font-size: 24px;
  margin-bottom: 20px;
}

.btn-secondary {
  background-color: white;
  color: rgb(37 99 235);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.btn-secondary:hover {
  background-color: #e0e0e0;
}

.section {
  padding: 60px 20px;
  text-align: center;
}

.section-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 50px;
}

.salary-section {
  text-align: center;
  padding: 60px 20px;
}

.salary-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.salary-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.salary-card h3 {
  font-size: 20px;
  font-weight: bold;
}

.salary-amount {
  font-size: 30px;
  font-weight: bold;
  color: rgb(37 99 235); 
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.benefit-card {
  padding: 20px;
  background-color: #f3f4f6;
  border-radius: 10px;
  text-align: center;
  transition: 0.3s ease-in-out;
}

.benefit-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.benefit-icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.benefit-title {
  font-size: 22px;
  font-weight: bold;
}
.benefit-description {
  font-size: 16px;
  font-weight: normal;
}

.success-section {
  padding: 60px;
}

.success-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.success-card {
  background-color: #f3f4f6;
  padding: 24px;
  border-radius: 12px;
}

.success-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.success-profile {
  display: flex;
  margin-bottom: 16px;
}

.profile-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 16px;
}

.profile-info h3 {
  font-size: 20px;
  font-weight: 600;
}

.role {
  font-size: 14px;
  color: #666;
  margin: 4px 0 0 0;
}

.testimonial {
  font-size: 16px;
  color: #444;
  margin: 0;
}

.eligibility-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.eligibility-card {
  background-color: #f3f4f6;
  padding: 20px;
  border-radius: 10px;
}

.eligibility-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: gray;
}

.icon-check {
  color: rgb(37 99 235);
  margin-right: 10px;
}

/* Enrollment Form */
.enrollment-section {
  padding: 60px 20px;
}

.enrollment-form {
  max-width: 600px;
  margin: 0 auto;
  background-color: #f3f4f6;
  padding: 40px;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
}

.form-input {
  width: 100%;
  padding: 12px !important;
  font-size: 16px;
  border: 1px solid rgb(200, 208, 226) !important;
  background: #ffffff !important;
}

.phone-input-container {
  display: flex;
  gap: 12px;
}

.country-select {
  width: 180px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: #fff !important;
}

.phone-input {
  flex: 1;
  background: #fff;
}

.register-btn {
  width: 100%;
  padding: 14px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: rgb(37 99 235);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 8px;
}

.register-btn:hover {
  background: rgb(29 78 216);
}

.form-input::placeholder {
  color: #999;
}

.form-input:focus,
.country-select:focus {
  outline: none;
  border-color: rgb(37 99 235);
}

/* Footer */
.footer {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  color: white;
  text-align: center;
  padding: 50px;
}
.footer h1 {
  color: white;
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 15px;
}
