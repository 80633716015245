/******************************************/
/*    client logos block in placements    */
/******************************************/

#clients {
  position: relative;
  height: 4.5rem;
  background: var(--bg-white-color);
}

.placement-page #clients {
  background: var(--bg-white-color);
}

.client-logos-block {
  padding: 1rem 1rem 1rem 0;
  margin-top: 0px;
  background: var(--bg-white-color);
  position: relative;
  background-color: #e8f2fe;
  border-radius: 0px 0px 30px 30px;
  top: -0.4rem;
}

.client-logos-block img {
  display: inline-block;
  padding: 0px 0 0 50px;
  height: 29px;
  width: 24%;
}

.client-logos-block img:first-child {
  padding: 0;
  width: 15%;
}

.client-logos-block img:last-child {
  width: 18%;
}
.client-logos img:nth-child(3) {
  
  width: 25%;
}
.client-logos img:nth-child(2) {
  
  width: 20%;
}


.client-logos-txt {
  border-right: 1px solid var(--border-color);
  padding: 0;
}

.client-logos-txt h4 {
  font-size: var(--font-x-size);
  font-family: var(--body-font);
  font-weight: var(--font-weight-regular);
  margin: 0;
  color: var(--txt-color-Htag);
  line-height: 1.5;
}

.client-logos-txt h3 {
  font-size: var(--font-xl-size);
  margin: 0;
  color: var(--txt-color-Htag);
  line-height: 1;
}

.client-logos-txt {
  width: auto;
  padding: 0px 30px;
  display: block;
}

.client-logos {
  width: 75%;
  display: flex;
  align-items: center;
  height: 100%;
  margin: auto;
  padding: 0px 30px;
  justify-content: center;
}


/*************************/
/*     Media Queries     */
/*************************/

/* Existing styles... */


@media screen and (min-width: 769px) and (max-width: 992px) {
  .client-logos {
    position: absolute;
    left: 12rem;
    width: 80%;
  }
  .client-logos-block img:first-child {
    
    width: 17%;
  }
  .client-logos-block img:last-child {
    width: 25%;
    margin-right: 10px;
  }
  
}

@media screen and (max-width: 667px) {
  body {
    overflow-x: hidden !important;
  }
}


@media (min-width: 481px) and (max-width: 768px) {
 
  .client-logos-block img {
    padding: 0;
    width: 20%;
  
  }

  .client-logos-block img:first-child {
    width: 20%;
  }

  .client-logos-block img:last-child {
    width: 25%;
  }
  .client-logos img:nth-child(3) {
    
   
    width: 20%;
  }
  .client-logos {
    width: 78%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 8rem;
  }

  .client-logos-txt {
    padding: 10px;
  }
  .client-logos-txt h4 {
    margin-top: 10px;
    font-size: 1rem;
  }
  .client-logos-txt h3 {
    font-size: 1rem;
  }
}

@media (min-width: 395px) and (max-width: 480px) {
  .client-logos-txt {
    border: 0;
    width: 100%;
    border-bottom: 1px solid #c2c2c2;
    margin-bottom: 5px;
    padding-bottom: 10px;
    
  }

  .client-logos-txt h3 {
    font-size: var(--font-x-size);
  }

  .client-logos {
    width: 100%;
    padding: 2rem;
  }

  .client-logos-block img {
    width: 30%;
    height: auto;
  }
  .client-logos img:nth-child(2) {
    position: absolute;
    right: 10px;
    width: 25%;
  }

  .client-logos img:nth-child(3) {
    position: absolute;
    left: 5rem;
    width: 25%;
  }

  .client-logos-block img:nth-child(4),
  .client-logos-block img:last-child {
    width: 30%;
    position: absolute;
    right: 7rem;
  }

  .client-logos-block img:first-child {
    width: 20%;
    position: absolute;
    left: 0.9rem;
  }
}

@media screen and (min-width:200px) and (max-width:394px) {
  .client-logos {
    width: 100%;
    margin-top: 1rem;
    position: relative;
    margin-bottom: 2rem;
    
  }

  .client-logos-txt {
    border: 0;
    width: 100%;
    border-bottom: 1px solid #c2c2c2;
    margin-bottom: 5px;
    padding-bottom: 10px;
    
  }
  .client-logos-txt h3 {
    font-size: var(--font-x-size);
  }

  .client-logos-block img {
    width: 30%;
    height: auto;
  }

  .client-logos-block img:last-child {
    width: 30%;
    position: absolute;
    right: 1rem;
  }

  .client-logos img:nth-child(3) {
    position: absolute;
    left: 5rem;
    width: 28%;
  }
  .client-logos-block img:first-child {
    width: 20%;
    position: absolute;
    left: 0.9rem;
  }

  .client-logos img:nth-child(2) {
   width: 30%;
   margin-left: 3rem;
  } 


}
